.futer_container{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    background-color: black;
    .futer_box{
        display: inline-block;
        margin: auto;
        color: white;
        font-family: "Catamaran", sans-serif;
        font-weight: 300;
        
        text-align: center;
        p{
            align-content: center;
            font-size: 12px;
        }
        
    }
}