@import '../../../constants/styles/null_file.scss';
@import '../../../constants/styles/variables.scss';

.contact_btn {

    .box_top {
        position: fixed;
        top: 40%;
        right: 0;
        z-index: 2;
        width: 40px;
        height: 15vh;
        cursor: pointer;
        border-left: 2px solid #0a9995;
        border-top: 2px solid #0a9995;
        border-bottom: 2px solid #0a9995;
        border-top-left-radius: 5px;
        -webkit-border-top-left-radius: 5px;
        -moz-border-top-left-radius: 5px;
        -ms-border-top-left-radius: 5px;
        -o-border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-bottom-left-radius: 5px;
        -ms-border-bottom-left-radius: 5px;
        -o-border-bottom-left-radius: 5px;
    }

    .box_bottom {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.137);
        top: 41%;
        right: 0;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 15vh;
        cursor: pointer;
        border-left: 1px dotted #0a9995;
        border-top: 1px dotted #0a9995;
        border-bottom: 1px dotted #0a9995;
        border-top-left-radius: 5px;
        -webkit-border-top-left-radius: 5px;
        -moz-border-top-left-radius: 5px;
        -ms-border-top-left-radius: 5px;
        -o-border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-bottom-left-radius: 5px;
        -ms-border-bottom-left-radius: 5px;
        -o-border-bottom-left-radius: 5px;

        .letter {
            text-align: center;
            margin-top: 7px;
            transform: rotate(-90deg);
            padding: 0;
            width: 15px;
            height: 15px;
            fill: #0a9995;
            -webkit-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);

            @media only screen and (max-width: 380px) {
                width: 12px;
                height: 12px;
            }
        }

        .sign_contact {
            text-align: center;
            color: white;
            font-size: 12px;
            margin-bottom: 15px;
            display: inline-block;
            transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);

            @media only screen and (max-width: 380px) {
                font-size: 1.3vh;
            }

        }

    }
}

.popup_back {
    background-color: $background;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    overflow-x: auto;
    overflow-y: hidden;
    z-index: 4;
    transition: all 0.8s ease 0s;
    -webkit-transition: all 0.8s ease 0s;
    -moz-transition: all 0.8s ease 0s;
    -ms-transition: all 0.8s ease 0s;
    -o-transition: all 0.8s ease 0s;


    .popup_body {
        min-height: 100%;
        display: flex;
        padding: 15px 15px;
        justify-content: center;
        align-items: center;
        transition: all 0.8s ease 0s;
        -webkit-transition: all 0.8s ease 0s;
        -moz-transition: all 0.8s ease 0s;
        -ms-transition: all 0.8s ease 0s;
        -o-transition: all 0.8s ease 0s;



        .popup_content {
            width: 60vw;
            background-color: white;
            border-radius: 5px;
            padding: 0;
            opacity: 0;
            transition: all 1.2s ease 0s;
            -webkit-transition: all 1.2s ease 0s;
            -moz-transition: all 1.2s ease 0s;
            -ms-transition: all 1.2s ease 0s;
            -o-transition: all 1.2s ease 0s;
            transform: perspective(600px) translate(100%, 0px) scale(0.2, 0.2) rotate(45deg);
            -webkit-transform: perspective(600px) translate(100%, 0px) scale(0.2, 0.2) rotate(45deg);
            -moz-transform: perspective(600px) translate(100%, 0px) scale(0.2, 0.2) rotate(45deg);
            -ms-transform: perspective(600px) translate(100%, 0px) scale(0.2, 0.2) rotate(45deg);
            -o-transform: perspective(600px) translate(100%, 0px) scale(0.2, 0.2) rotate(45deg);

            @media only screen and (max-width: 960px) {
                width: 90vw;
            }

            @media only screen and (max-width: 780px) {
                width: 90vw;
            }

            .title_popup {
                width: 100%;
                height: 7vh;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                box-shadow: 0 5px 5px -5px rgba(0, 0, 0, .5);
                padding: 10px 0 10px;
                margin-bottom: 20px;
                box-sizing: border-box;
                text-align: center;
                background-color: rgb(219, 218, 218);
                background: linear-gradient(to top, rgb(206, 206, 206) 20%, rgb(213, 213, 213) 30%, rgb(215, 215, 215) 50%, rgb(213, 213, 213) 70%, rgb(206, 206, 208) 80%);
                border-bottom: 1px solid darkgray;
                position: relative;

                @media only screen and (max-width: 560px) {
                    margin-bottom: 0.8vh;
                }

                .close_popup {
                    border-radius: 50%;
                    padding: 3px;
                    background-color: white;
                    position: absolute;
                    margin-right: 5px;
                    margin-top: 3px;
                    top: 10px;
                    right: 8px;
                    width: 15px;
                    height: 15px;
                    cursor: pointer;
                    fill: rgb(80, 80, 80);
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                }

                h2 {
                    margin: auto;
                    font-family: "Catamaran", sans-serif;
                    font-weight: 700;
                    font-size: 3vh;
                    color: #0a9995;
                }
            }

            .row {
                padding: 1%;
                display: flex;
                justify-content: space-around;
                margin-bottom: 20px;

                @media only screen and (max-width: 560px) {
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 5px;
                }

                .adress {
                    width: 40%;
                    margin-right: 20px;

                    @media only screen and (max-width: 560px) {
                        width: 75%;
                        margin: 0 10px 10px 10px;
                    }

                    .title {
                        border-bottom: 1px solid black;
                        display: flex;
                        margin-bottom: 12px;
                        align-items: baseline;

                        .location {
                            width: 25px;
                            height: 25px;
                            fill: #0a9995;
                            margin-right: 4px;

                            @media only screen and (max-width: 560px) {
                                width: 15px;
                                height: 15px;
                            }
                        }

                        h3 {
                            display: block;
                            font-family: "Catamaran", sans-serif;
                            font-weight: 600;
                            font-size: 18px;
                            margin-bottom: 2px;
                            line-height: 18px;
                            color: #0a9995;
                        }
                    }

                    p {
                        color: black;
                        font-family: "Catamaran", sans-serif;
                        font-weight: 400;
                        font-size: 2vh;
                        line-height: 2.1vh;
                        margin-bottom: 1vh;

                    }

                    .email {
                        display: flex;
                        flex-direction: column;

                        @media only screen and (max-width: 560px) {
                            justify-content: flex-start;
                        }

                        .row_email {
                            display: flex;
                            justify-content: space-between;
                            align-items: baseline;
                            margin-bottom: 5px;


                            a {
                                text-decoration: none;
                                margin: 0;
                                display: block;

                                @media only screen and (max-width: 560px) {
                                    margin: 0 40px 0 0;
                                }

                                p {
                                    font-size: 2vh;
                                    color: black;
                                    font-family: "Catamaran", sans-serif;
                                    font-weight: 400;

                                    @media only screen and (max-width: 560px) {

                                        line-height: 13px;
                                    }
                                }
                            }

                            .copyed {
                                cursor: pointer;
                                width: 20px;
                                height: 20px;
                                fill: #0a9995;

                                @media only screen and (max-width: 560px) {
                                    padding: 0 0 0 12px;
                                }

                                @media only screen and (max-width: 360px) {
                                    padding: 0 0 0 8px;
                                }
                            }

                            .email_copy {
                                .tooltip {
                                    color: #0a9995;
                                    font-family: "Catamaran", sans-serif;
                                    font-weight: 400;
                                    font-size: 15px;
                                    position: absolute;
                                    top: -20px;
                                    left: 15px;

                                    @media only screen and (max-width: 560px) {
                                        top: -20px;
                                        left: 15px;
                                    }

                                }
                            }

                        }

                        .row_button {
                            display: flex;
                            justify-content: center;

                            .button {
                                width: 100%;
                                display: flex;
                                justify-content: center;

                                @media only screen and (max-width: 560px) {
                                    margin-bottom: 10px;
                                }

                                a {
                                    text-decoration: none;
                                    display: block;
                                    width: 80%;
                                    height: 20px;
                                    padding: 4px 5px;
                                    text-align: center;
                                    background-color: #0a9995;
                                    border-radius: 5px;
                                    -webkit-border-radius: 5px;
                                    -moz-border-radius: 5px;
                                    -ms-border-radius: 5px;
                                    -o-border-radius: 5px;

                                    p {
                                        color: white;
                                        margin: 0;
                                        font-size: 14px;
                                        line-height: 20px;
                                        font-family: "Catamaran", sans-serif;
                                        font-weight: 400;
                                    }

                                }
                            }
                        }
                    }
                }

                .map {
                    width: 50%;
                    height: 28vh;
                    overflow: hidden;
                    position: relative;

                    @media only screen and (max-width: 560px) {
                        width: 85%;
                        margin: 0 10px 0 10px;
                        height: 15vh;
                    }

                    img {

                        width: 100%;
                        height: auto;

                        border: 0;
                        border-radius: 5px;
                        -webkit-border-radius: 5px;
                        -moz-border-radius: 5px;
                        -ms-border-radius: 5px;
                        -o-border-radius: 5px;
                    }

                    .button_more {
                        position: absolute;
                        text-decoration: none;
                        text-align: center;
                        line-height: 25px;
                        width: 45%;
                        height: 25px;
                        top: 5px;
                        left: 5px;
                        display: block;
                        background-color: lightgrey;
                        color: black;
                        font-family: "Catamaran", sans-serif;
                        font-weight: 400;
                        font-size: 15px;
                        border-radius: 5px;
                        -webkit-border-radius: 5px;
                        -moz-border-radius: 5px;
                        -ms-border-radius: 5px;
                        -o-border-radius: 5px;


                        @media only screen and (max-width: 360px) {
                            width: 55%;
                        }
                    }
                }

            }

            .container_mes {
                width: 70%;
                padding: 1%;
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;

                @media only screen and (max-width: 760px) {
                    width: 75%;
                }

                @media only screen and (max-width: 560px) {
                    width: 95%;
                }

                .title_mes {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 15px;

                    @media only screen and (max-width: 560px) {
                        margin-bottom: 8px;
                    }

                    .call_mess {
                        width: 15%;
                        display: flex;
                        border-bottom: 1px solid black;
                        padding-bottom: 5px;
                        justify-content: flex-end;
                        margin: 0;

                        .call_message {
                            text-align: right;
                            display: block;
                            width: 35px;
                            height: 35px;
                            fill: #0a9995;
                            margin: 0 7px 0 0;

                            @media only screen and (max-width: 560px) {
                                width: 25px;
                                height: 25px;
                            }
                        }

                    }

                    .div_h3 {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        border-bottom: 1px solid black;
                        padding-right: 15px;
                        padding-bottom: 5px;

                        h3 {

                            display: block;
                            margin: 0;
                            font-size: 18px;
                            font-family: "Catamaran", sans-serif;
                            font-weight: 500;

                            @media only screen and (max-width: 560px) {
                                font-size: 14px;
                            }
                        }
                    }

                }

                .row_phones {
                    width: 100%;
                    display: flex;
                    margin: 0 auto 15px;
                    flex-wrap: wrap;
                    align-items: center;


                    @media only screen and (max-width: 560px) {
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 8px;
                    }

                    .phone_norw,
                    .phone_ukr {
                        display: flex;
                        align-items: baseline;
                        width: 50%;

                        @media only screen and (max-width: 560px) {
                            width: 70%;
                            margin-bottom: 8px;
                            height: 30px;
                        }

                        .icon {
                            width: 15%;

                            .messenger_icon {
                                width: 15px;
                                height: 15px;
                                fill: #0a9995;
                            }
                        }

                        .number {
                            width: 65%;
                            margin-right: 7px;

                            a {
                                text-decoration: none;

                                p {
                                    color: black;
                                    font-family: "Catamaran", sans-serif;
                                    font-weight: 400;
                                    font-size: 15px;

                                    @media only screen and (max-width: 380px) {
                                        font-size: 2vh;
                                        margin-left: 3px;
                                    }
                                }
                            }
                        }


                        .copy_icon {
                            width: 25%;
                            width: 25%;

                            .copyed {
                                width: 20px;
                                height: 20px;
                                fill: #0a9995;
                            }

                        }
                    }

                    .phone_ukr {
                        @media only screen and (max-width: 560px) {
                            margin-bottom: 2vh;
                        }

                    }
                }

                .row_messenger {
                    width: 80%;
                    margin: auto;
                    display: flex;
                    justify-content: space-around;



                    @media only screen and (max-width: 780px) {
                        justify-content: center;
                        width: 100%;
                    }

                    .icon_messenger {
                        width: 10%;
                        margin: 0 5px;

                        a {
                            text-decoration: none;

                            .messenger_icon {
                                width: 45px;
                                height: 45px;

                                @media only screen and (max-width: 560px) {
                                    width: 30px;
                                    height: 30px;
                                }
                            }
                        }


                    }
                }
            }

        }
    }
}

.popup_back_open {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 4;
    top: 0;
    right: 0;
    opacity: 1;
    visibility: visible;
    overflow-x: auto;
    overflow-y: hidden;

    .popup_body {
        min-height: 100%;
        display: flex;

        padding-right: 15px;
        justify-content: center;
        align-items: center;
        transition: all 0.8s ease 0s;
        -webkit-transition: all 0.8s ease 0s;
        -moz-transition: all 0.8s ease 0s;
        -ms-transition: all 0.8s ease 0s;
        -o-transition: all 0.8s ease 0s;


        .popup_content {
            width: 60vw;
            background-color: white;
            border-radius: 5px;
            padding: 0;
            opacity: 1;
            transition: all 1.2s ease 0s;
            -webkit-transition: all 1.2s ease 0s;
            -moz-transition: all 1.2s ease 0s;
            -ms-transition: all 1.2s ease 0s;
            -o-transition: all 1.2s ease 0s;

            transform: perspective(600px) translate(0px, 0%) scale(1, 1) rotateY(0deg);
            -webkit-transform: perspective(600px) translate(0px, 0%) scale(1, 1) rotateY(0deg);
            -moz-transform: perspective(600px) translate(0px, 0%) scale(1, 1) rotateY(0deg);
            -ms-transform: perspective(600px) translate(0px, 0%) scale(1, 1) rotateY(0deg);
            -o-transform: perspective(600px) translate(0px, 0%) scale(1, 1) rotateY(0deg);

            @media only screen and (max-width: 960px) {
                width: 90vw;
            }

            @media only screen and (max-width: 780px) {
                width: 90vw;
            }

            .title_popup {
                width: 100%;
                height: 7vh;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                box-shadow: 0 5px 5px -5px rgba(0, 0, 0, .5);
                padding: 10px 0 10px;
                margin-bottom: 20px;
                box-sizing: border-box;
                text-align: center;
                background-color: rgb(219, 218, 218);
                background: linear-gradient(to top, rgb(206, 206, 206) 20%, rgb(213, 213, 213) 30%, rgb(215, 215, 215) 50%, rgb(213, 213, 213) 70%, rgb(206, 206, 208) 80%);
                border-bottom: 1px solid darkgray;
                position: relative;

                @media only screen and (max-width: 560px) {
                    margin-bottom: 0.8vh;
                }

                .close_popup {
                    border-radius: 50%;
                    padding: 3px;
                    background-color: white;
                    position: absolute;
                    margin-right: 5px;
                    margin-top: 3px;
                    top: 10px;
                    right: 8px;
                    width: 15px;
                    height: 15px;
                    cursor: pointer;
                    fill: rgb(80, 80, 80);
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                }

                h2 {
                    margin: auto;
                    font-family: "Catamaran", sans-serif;
                    font-weight: 700;
                    font-size: 3vh;
                    color: #0a9995;
                }
            }

            .row {
                padding: 1%;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                margin-bottom: 20px;


                @media only screen and (max-width: 560px) {
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 1vh;

                }

                .adress {
                    width: 40%;
                    margin-right: 20px;

                    @media only screen and (max-width: 560px) {
                        width: 75%;
                        margin: 0 10px 10px 10px;
                    }

                    .title {
                        border-bottom: 1px solid black;
                        display: flex;
                        align-items: baseline;
                        margin-bottom: 15px;

                        .location {
                            width: 25px;
                            height: 25px;
                            fill: #0a9995;
                            margin-right: 4px;

                            @media only screen and (max-width: 560px) {
                                width: 15px;
                                height: 15px;
                            }
                        }

                        h3 {
                            display: block;
                            font-family: "Catamaran", sans-serif;
                            font-weight: 600;
                            font-size: 18px;
                            margin-bottom: 2px;
                            line-height: 22px;
                            color: #0a9995;
                        }
                    }

                    p {
                        color: black;
                        font-family: "Catamaran", sans-serif;
                        font-weight: 400;
                        font-size: 2vh;
                        line-height: 15px;
                        margin-bottom: 1vh;

                    }

                    .email {
                        display: flex;
                        flex-direction: column;

                        .row_email {
                            display: flex;
                            justify-content: space-between;
                            align-items: baseline;
                            margin-bottom: 5px;

                            @media only screen and (max-width: 560px) {
                                justify-content: flex-start;
                            }

                            a {
                                text-decoration: none;
                                margin: 0;
                                display: block;

                                @media only screen and (max-width: 560px) {
                                    margin: 0 40px 0 0;
                                }

                                p {
                                    font-size: 2vh;
                                    color: black;
                                    font-family: "Catamaran", sans-serif;
                                    font-weight: 400;

                                    @media only screen and (max-width: 560px) {

                                        line-height: 13px;
                                    }
                                }
                            }

                            .copyed {
                                width: 20px;
                                height: 20px;
                                fill: #0a9995;
                                position: relative;
                                cursor: pointer;

                                @media only screen and (max-width: 360px) {
                                    margin-left: -10px;
                                }

                                .email_copy {
                                    .tooltip {
                                        color: #0a9995;
                                        font-family: "Catamaran", sans-serif;
                                        font-weight: 400;
                                        font-size: 15px;
                                        position: absolute;
                                        z-index: 4;
                                        top: -20px;
                                        left: 15px;
                                        animation: tip 2s;
                                        -webkit-animation: tip 2s;

                                        @keyframes tip {
                                            0% {
                                                opacity: 0;

                                            }

                                            50% {
                                                opacity: 1;
                                            }

                                            100% {
                                                opacity: 0;
                                            }
                                        }

                                        @media only screen and (max-width: 560px) {
                                            top: -20px;
                                            left: 15px;
                                        }

                                    }
                                }


                            }
                        }

                        .row_button {
                            display: flex;
                            justify-content: center;

                            .button {
                                width: 100%;
                                display: flex;
                                justify-content: center;

                                @media only screen and (max-width: 560px) {
                                    margin-bottom: 10px;
                                }

                                a {
                                    text-decoration: none;
                                    display: block;
                                    width: 80%;
                                    height: 20px;
                                    padding: 4px 5px;
                                    text-align: center;
                                    background-color: #0a9995;
                                    border-radius: 5px;
                                    -webkit-border-radius: 5px;
                                    -moz-border-radius: 5px;
                                    -ms-border-radius: 5px;
                                    -o-border-radius: 5px;

                                    p {
                                        color: white;
                                        margin: 0;
                                        font-size: 14px;
                                        line-height: 22px;
                                        font-family: "Catamaran", sans-serif;
                                        font-weight: 400;
                                    }

                                }
                            }
                        }
                    }
                }

                .map {
                    width: 50%;
                    height: 28vh;
                    overflow: hidden;
                    position: relative;

                    @media only screen and (max-width: 560px) {
                        width: 85%;
                        margin: 0 10px 0 10px;
                        height: 15vh;
                    }

                    img {

                        width: 100%;
                        height: auto;
                        border: 0;
                        border-radius: 5px;
                        -webkit-border-radius: 5px;
                        -moz-border-radius: 5px;
                        -ms-border-radius: 5px;
                        -o-border-radius: 5px;
                    }

                    .button_more {
                        position: absolute;
                        text-decoration: none;
                        text-align: center;
                        line-height: 25px;
                        width: 45%;
                        height: 25px;
                        top: 5px;
                        left: 5px;
                        display: block;
                        background-color: lightgrey;
                        color: black;
                        font-family: "Catamaran", sans-serif;
                        font-weight: 400;
                        font-size: 15px;
                        border-radius: 5px;
                        -webkit-border-radius: 5px;
                        -moz-border-radius: 5px;
                        -ms-border-radius: 5px;
                        -o-border-radius: 5px;

                        @media only screen and (max-width: 360px) {
                            width: 55%;
                        }
                    }
                }

            }

            .container_mes {
                width: 70%;
                padding: 1%;
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;

                @media only screen and (max-width: 760px) {
                    width: 75%;
                }

                @media only screen and (max-width: 560px) {
                    width: 95%;
                }

                .title_mes {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 15px;

                    @media only screen and (max-width: 560px) {
                        margin-bottom: 8px;
                    }


                    .call_mess {
                        width: 15%;
                        display: flex;
                        border-bottom: 1px solid black;
                        padding-bottom: 5px;
                        justify-content: flex-end;
                        margin: 0;

                        .call_message {
                            text-align: right;
                            display: block;
                            width: 35px;
                            height: 35px;
                            fill: #0a9995;
                            margin: 0 7px 0 0;

                            @media only screen and (max-width: 560px) {
                                width: 25px;
                                height: 25px;
                            }
                        }

                    }

                    .div_h3 {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        border-bottom: 1px solid black;
                        padding-right: 15px;
                        padding-bottom: 5px;
                        line-height: 41px;
                        @media only screen and (max-width: 560px) {
                            line-height: 30px;
                        }

                        h3 {

                            display: block;
                            margin: 0;
                            font-size: 18px;
                            font-family: "Catamaran", sans-serif;
                            font-weight: 500;

                            @media only screen and (max-width: 560px) {
                                font-size: 14px;
                            }

                        }
                    }

                }

                .row_phones {
                    width: 100%;
                    display: flex;
                    margin: 0 auto 15px;
                    flex-wrap: wrap;
                    align-items: center;

                    @media only screen and (max-width: 560px) {
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 8px;
                    }

                    .phone_norw,
                    .phone_ukr {
                        display: flex;
                        align-items: baseline;
                        width: 50%;

                        @media only screen and (max-width: 560px) {
                            width: 70%;
                            margin-bottom: 8px;
                            height: 30px;
                        }

                        .icon {
                            width: 10%;

                            .messenger_icon {
                                width: 15px;
                                height: 15px;
                                fill: #0a9995;
                            }
                        }

                        .number {
                            width: 65%;
                            margin-right: 7px;

                            a {
                                text-decoration: none;

                                p {
                                    color: black;
                                    font-family: "Catamaran", sans-serif;
                                    font-weight: 400;
                                    font-size: 15px;

                                    @media only screen and (max-width: 380px) {
                                        font-size: 2vh;
                                        margin-left: 3px;
                                    }
                                }
                            }
                        }

                        .copy_icon {
                            width: 25%;
                            position: relative;

                            .copyed {
                                width: 20px;
                                height: 20px;
                                fill: #0a9995;
                                cursor: pointer;

                            }

                            .norw_tel,
                            .ukr_tel {
                                .tooltip {
                                    color: #0a9995;
                                    font-family: "Catamaran", sans-serif;
                                    font-weight: 400;
                                    font-size: 15px;
                                    position: absolute;
                                    top: -20px;
                                    left: 15px;
                                    animation: tip 2s;
                                    -webkit-animation: tip 2s;

                                    @keyframes tip {
                                        0% {
                                            opacity: 0;

                                        }

                                        50% {
                                            opacity: 1;
                                        }

                                        100% {
                                            opacity: 0;
                                        }
                                    }

                                }

                            }


                        }


                    }

                    .phone_ukr {
                        @media only screen and (max-width: 560px) {
                            margin-bottom: 2vh;
                        }

                    }




                }

                .row_messenger {
                    width: 80%;
                    margin: auto;
                    display: flex;
                    justify-content: space-around;


                    @media only screen and (max-width: 780px) {
                        justify-content: center;
                        width: 100%;
                    }

                    .icon_messenger {
                        width: 10%;
                        margin: 0 5px;

                        a {
                            text-decoration: none;

                            .messenger_icon {
                                width: 45px;
                                height: 45px;

                                @media only screen and (max-width: 560px) {
                                    width: 30px;
                                    height: 30px;
                                }
                            }
                        }


                    }
                }
            }

        }
    }
}

.box_top {
    position: fixed;
    top: 40%;
    right: 0;
    z-index: 2;
    width: 40px;
    height: 15vh;
    cursor: pointer;
    border-left: 2px solid #0a9995;
    border-top: 2px solid #0a9995;
    border-bottom: 2px solid #0a9995;
    border-top-left-radius: 5px;
    -webkit-border-top-left-radius: 5px;
    -moz-border-top-left-radius: 5px;
    -ms-border-top-left-radius: 5px;
    -o-border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-bottom-left-radius: 5px;
    -ms-border-bottom-left-radius: 5px;
    -o-border-bottom-left-radius: 5px;
}

.box_bottom {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.137);
    top: 41%;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 15vh;
    cursor: pointer;
    border-left: 1px dotted #0a9995;
    border-top: 1px dotted #0a9995;
    border-bottom: 1px dotted #0a9995;
    border-top-left-radius: 5px;
    -webkit-border-top-left-radius: 5px;
    -moz-border-top-left-radius: 5px;
    -ms-border-top-left-radius: 5px;
    -o-border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-bottom-left-radius: 5px;
    -ms-border-bottom-left-radius: 5px;
    -o-border-bottom-left-radius: 5px;

    .letter {
        text-align: center;
        transform: rotate(-90deg);
        padding: 0;
        width: 15px;
        height: 15px;
        fill: #0a9995;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);

        @media only screen and (max-width: 380px) {
            width: 12px;
            min-height: 12px;
        }
    }

    .sign_contact {
        text-align: center;
        color: white;
        font-size: 12px;
        margin-bottom: 15px;
        display: inline-block;
        transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);


    }

}

.close_popup {
    border-radius: 50%;
    padding: 3px;
    background-color: white;
    position: absolute;
    margin-right: 5px;
    margin-top: 3px;
    top: 10px;
    right: 8px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    fill: rgb(80, 80, 80);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}