@import '../../../constants/styles/null_file.scss';
@import '../../../constants/styles/variables.scss';


.body_container {
    max-width: 100%;
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media only screen and (max-width: 560px) {
        padding: 15px 0;
    }

    .container_goal {
        display: flex;
        margin: 25px 0;
        justify-content: space-between;
        padding: 80px 0;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            padding: 50px 0;

        }

        @media only screen and (max-width: 560px) {
            margin: 15px 0;
            padding: 30px 0;
        }

        .box_goal_text {
            display: flex;
            flex-direction: column;
            width: 80%;

            .box_h2 {
                @extend %h2_style;
                margin-left: 60px;
                height: 35%;
                animation: box_h2_skills 2000ms ease;
                -webkit-animation: box_h2_skills 2000ms ease;
            }

            @keyframes box_h2_skills {
                from {
                    opacity: 0;
                    transform: translateX(-1000px);
                    -webkit-transform: translateX(-1000px);
                    -moz-transform: translateX(-1000px);
                    -ms-transform: translateX(-1000px);
                    -o-transform: translateX(-1000px);
                }

                to {
                    opacity: 1;
                    transform: none;
                    -webkit-transform: none;
                    -moz-transform: none;
                    -ms-transform: none;
                    -o-transform: none;
                }
            }

            @media only screen and (max-width: 560px) {

                @keyframes box_h2_skills {
                    from {
                        opacity: 0;
                        transform: translateX(-100px);
                        -webkit-transform: translateX(-100px);
                        -moz-transform: translateX(-100px);
                        -ms-transform: translateX(-100px);
                        -o-transform: translateX(-100px);
                    }

                    to {
                        opacity: 1;
                        transform: none;
                        -webkit-transform: none;
                        -moz-transform: none;
                        -ms-transform: none;
                        -o-transform: none;
                    }
                }
            }

            .box_h2_hidden {
                opacity: 0;
            }

            .box_line {
                width: 85%;
                border-top: 1px solid $graphite;
                margin-bottom: 25px;
                margin-left: 60px;
                animation: box_line 3000ms ease;
                -webkit-animation: box_line 3000ms ease;
            }

            .box_line_hidden {
                opacity: 0;
            }

            @keyframes box_line {
                from {
                    opacity: 0;
                    transform: translateX(-1500px);
                    -webkit-transform: translateX(-1500px);
                    -moz-transform: translateX(-1500px);
                    -ms-transform: translateX(-1500px);
                    -o-transform: translateX(-1500px);
                }

                to {
                    opacity: 1;
                    transform: translateX(0);
                    -webkit-transform: translateX(0);
                    -moz-transform: translateX(0);
                    -ms-transform: translateX(0);
                    -o-transform: translateX(0);
                }
            }

            .text_hidden {
                opacity: 0;
            }

            .text {
                color: $graphite;
                @extend %big_description;
                height: 35%;
                margin-bottom: 20px;
                margin-left: 30px;
                animation: box_text 3000ms ease;
                -webkit-animation: box_text 3000ms ease;

            }

            @keyframes box_text {
                from {
                    opacity: 0;
                    transform: translate(0px, 120%) scale(0.1, 0);
                    -webkit-transform: translate(0px, 120%) scale(0.1, 0);
                    -moz-transform: translate(0px, 120%) scale(0.1, 0);
                    -ms-transform: translate(0px, 120%) scale(0.1, 0);
                    -o-transform: translate(0px, 120%) scale(0.1, 0);
                }

                to {
                    opacity: 1;
                    transform: translate(0, 0) scale(1, 1);
                    -webkit-transform: translate(0, 0) scale(1, 1);
                    -moz-transform: translate(0, 0) scale(1, 1);
                    -ms-transform: translate(0, 0) scale(1, 1);
                    -o-transform: translate(0, 0) scale(1, 1);
                }
            }

        }

        .box_image {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 30%;
            margin: 0 40px 0 20px;
            justify-content: center;
            animation: box_image 2000ms ease;
            -webkit-animation: box_image 2000ms ease;

            @media only screen and(max-width: 560px) {
                margin: auto;
            }

            .goalicon {
                width: 300px;
                height: 300px;
                fill: $mint;

                @media only screen and (max-width: 560px) {
                    width: 250px;
                    height: 250px;
                }

                @media only screen and (max-width: 360px) {
                    width: 180px;
                    height: 180px;
                }
            }
        }

        .box_image_hidden {
            opacity: 0;
        }

        @keyframes box_image {
            from {
                opacity: 0;
                transform: translateX(3500px);
                -webkit-transform: translateX(3500px);
                -moz-transform: translateX(3500px);
                -ms-transform: translateX(3500px);
                -o-transform: translateX(3500px);
            }

            to {
                opacity: 1;
                transform: none;
                -webkit-transform: none;
                -moz-transform: none;
                -ms-transform: none;
                -o-transform: none;
            }
        }
    }
}

.skill_box {
    width: 100%;
    background-image: url('../../../../public/it_tech.jpg');
    background-size: cover;

    @media only screen and (max-width: 720px) {
        background-image: url('../../../../public/it_tech_mob.jpg');
    }

    @media only screen and (max-width: 520px) {
        background-image: url('../../../../public/it_tech_mob_min.jpg');
    }

    .body_container {

        max-width: 1500px;
        margin: 0 auto;
        padding: 50px 20px 50px 30px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: space-between;

        @media only screen and (max-width: 960px) {
            padding: 30px 20px 30px 30px;
        }

        .title_skill {
            height: 30%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
            margin-bottom: 10vh;
            animation: title_skill 2000ms ease;
            -webkit-animation: title_skill 2000ms ease;
            margin-bottom: 25px;

            h2 {
                margin-top: 0;
                display: block;
                @extend %h2_style;
                text-align: end;
            }

            .box_line {
                width: 65%;
                border-top: 1px solid $mint;
            }
        }

        .title_skill_hidden {
            opacity: 0;
        }

        @keyframes title_skill {
            from {
                opacity: 0;
                transform: translateX(2000px) scale(0.1, 0);
                -webkit-transform: translateX(2000px) scale(0.1, 0);
                -moz-transform: translateX(2000px) scale(0.1, 0);
                -ms-transform: translateX(2000px) scale(0.1, 0);
                -o-transform: translateX(2000px) scale(0.1, 0);
            }

            to {
                opacity: 1;
                transform: translateX(0) scale(1, 1);
                -webkit-transform: translateX(0) scale(1, 1);
                -moz-transform: translateX(0) scale(1, 1);
                -ms-transform: translateX(0) scale(1, 1);
                -o-transform: translateX(0) scale(1, 1);
            }
        }

        @media only screen and (max-width: 560px) {
            @keyframes title_skill {
                from {
                    opacity: 0;
                    transform: translateX(150px) scale(0.1, 0);
                    -webkit-transform: translateX(150px) scale(0.1, 0);
                    -moz-transform: translateX(150px) scale(0.1, 0);
                    -ms-transform: translateX(150px) scale(0.1, 0);
                    -o-transform: translateX(150px) scale(0.1, 0);
                }

                to {
                    opacity: 1;
                    transform: translateX(0) scale(1, 1);
                    -webkit-transform: translateX(0) scale(1, 1);
                    -moz-transform: translateX(0) scale(1, 1);
                    -ms-transform: translateX(0) scale(1, 1);
                    -o-transform: translateX(0) scale(1, 1);
                }
            }
        }


        .body_skills {
            display: flex;
            justify-content: flex-end;
            height: 60%;

            @media only screen and (max-width: 520px) {
                justify-content: flex-start;
            }

            .skills_text {
                width: 45%;
                animation: skills_text 2000ms ease;
                -webkit-animation: skills_text 2000ms ease;

                @media only screen and(max-width: 720px) {
                    font-size: 16px;
                    width: 60%;
                }

                @media only screen and (max-width: 520px) {
                    width: 90%;
                }

                .skill_div {
                    line-height: 40px;
                    color: $white;
                    list-style-type: none;
                    @extend %big_description;

                    &::before {
                        content: '< >';
                        margin-right: 10px;
                    }

                    &::after {
                        content: 'ⓘ';
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }

                .more {
                    color: $white;
                    @extend %small_description;
                    display: none;
                }
            }

            .skills_text_hidden {
                opacity: 0;
            }

            @keyframes skills_text {
                from {
                    opacity: 0;
                    transform: translateY(120%) scale(0.1, 0.1);
                    -webkit-transform: translateY(120%) scale(0.1, 0.1);
                    -moz-transform: translateY(120%) scale(0.1, 0.1);
                    -ms-transform: translateY(120%) scale(0.1, 0.1);
                    -o-transform: translateY(120%) scale(0.1, 0.1);
                }

                to {
                    opacity: 1;
                    transform: translateY(0) scale(1, 1);
                    -webkit-transform: translateY(0) scale(1, 1);
                    -moz-transform: translateY(0) scale(1, 1);
                    -ms-transform: translateY(0) scale(1, 1);
                    -o-transform: translateY(0) scale(1, 1);
                }
            }
        }

    }


}

.skill_box_webp {
    @extend .skill_box;
    background-image: url('../../../../public/it_tech_.webp');
    background-size: cover;

    @media only screen and (max-width: 720px) {
        background-image: url('../../../../public/it_tech_mob_.webp');
    }

    @media only screen and (max-width: 520px) {
        background-image: url('../../../../public/it_tech_mob_min_.webp');
    }

}

.carusel_body {
    background-image: url('../../../../public/5570863.jpg');
    background-size: cover;

    .carusel_container {
        max-width: 1500px;
        margin: 0 auto;
        padding: 50px 20px;
        text-align: center;

        @media only screen and (max-width: 560px) {
            padding: 30px 10px;
        }

        @media only screen and (max-width: 420px) {
            padding: 30px 5px;
        }

        .title_carusel {
            h2 {
                @extend %h2_style;
            }
        }

        .box_window {
            margin: 0 auto 20px;
            display: flex;
            justify-content: center;
            align-items: baseline;

            .box_pageicon {
                margin: 0 5px;
                align-self: center;
                cursor: pointer;

                .pageicon {
                    width: 40px;
                    height: 40px;
                    fill: $mint;

                    @media only screen and (max-width: 560px) {
                        width: 25px;
                        height: 25px;
                    }

                    @media only screen and (max-width: 496px) {
                        display: none
                    }
                }

            }

            .carusel_window {
                width: 980px;
                overflow: hidden;

                @media only screen and (max-width: 1200px) {
                    width: 784px;
                }

                @media only screen and (max-width: 960px) {
                    width: 627px;
                }

                @media only screen and (max-width: 768px) {
                    width: 480px;
                }

                @media only screen and (max-width: 620px) {
                    width: 400px;
                }

                @media only screen and (max-width: 496px) {
                    width: 400px;
                }

                @media only screen and (max-width: 420px) {
                    width: 300px;
                }

                @media only screen and (max-width: 340px) {
                    width: 260px;
                }


                .box_job_text {
                    width: 2940px;
                    display: flex;
                    justify-content: space-around;

                    @media only screen and (max-width: 1200px) {
                        width: 2352px;

                    }

                    @media only screen and (max-width: 960px) {
                        width: 1881px;

                    }

                    @media only screen and (max-width: 768px) {
                        width: 2880px;

                    }

                    @media only screen and (max-width: 620px) {
                        width: 2400px;

                    }

                    @media only screen and (max-width: 496px) {
                        width: 2400px;

                    }

                    @media only screen and (max-width: 420px) {
                        width: 1800px;

                    }

                    @media only screen and (max-width: 340px) {
                        width: 1560px;

                    }

                    .item_job {
                        background-color: $white;
                        width: 370px;
                        justify-content: space-between;
                        display: flex;
                        flex-direction: column;
                        border: rgb(192, 192, 192) 1px solid;
                        border-radius: 5px;
                        align-items: center;
                        box-shadow: 0 0 5px 5px rgba(221, 221, 221, 1);
                        padding: 20px 10px;
                        -webkit-border-radius: 5px;
                        -moz-border-radius: 5px;
                        -ms-border-radius: 5px;
                        -o-border-radius: 5px;

                        @media only screen and (max-width: 1200px) {
                            width: 333px;

                        }

                        @media only screen and (max-width: 960px) {
                            width: 270px;

                        }

                        @media only screen and (max-width: 768px) {
                            width: 450px;

                        }

                        @media only screen and (max-width: 620px) {
                            width: 350px;

                        }

                        @media only screen and (max-width: 496px) {
                            width: 370px;
                            box-shadow: none;

                        }

                        @media only screen and (max-width: 420px) {
                            width: 280px;


                        }

                        @media only screen and (max-width: 340px) {
                            width: 250px;

                        }


                        .title_job {
                            @extend %h3_style;
                            color: $graphite;
                            height: 10%;
                        }

                        .position {
                            height: 10%;

                            p {
                                @extend %big_description;
                                color: $mint;
                                font-weight: $weight_h3;
                            }
                        }

                        .image_job {
                            height: 40%;
                            width: 80%;
                            margin: 0 auto 20px;
                            border-radius: 5px;
                            -webkit-border-radius: 5px;
                            -moz-border-radius: 5px;
                            -ms-border-radius: 5px;
                            -o-border-radius: 5px;

                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 5px;
                                -webkit-border-radius: 5px;
                                -moz-border-radius: 5px;
                                -ms-border-radius: 5px;
                                -o-border-radius: 5px;
                            }

                        }

                        .link_job {
                            height: 10%;
                            @extend %big_description;

                            a {
                                color: $mint;
                            }
                        }

                        .job_date {
                            height: 5%;
                            color: $graphite;
                            @extend %small_description;
                            margin-bottom: 12px;
                        }

                        .job_description {
                            height: 30%;
                            text-align: start;
                            padding: 2px;
                            color: $graphite;
                            @extend %small_description;
                            margin-bottom: 20px;

                            @media only screen and (max-width: 560px) {
                                font-size: 12px;
                                margin-bottom: 10px;
                            }

                            @media only screen and (max-width: 420px) {
                                font-size: 11px;
                            }

                            ul {
                                li {
                                    list-style-type: none;
                                    line-height: 25px;
                                    margin-bottom: 8px;

                                    &::before {
                                        content: '< >';
                                        margin-right: 10px;
                                        margin-left: 10px;
                                    }
                                }

                                a {
                                    color: $mint;
                                    @extend %small_description;
                                }
                            }
                        }
                    }
                }
            }
        }

        .dots_page {
            width: 150px;
            display: flex;
            justify-content: space-between;
            margin: auto;

            .dot {
                cursor: pointer;
                width: 12px;
                height: 12px;
                background-color: $light_grey;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
            }

            .dot:hover {
                background-color: $mint;
            }

            .dot_active {
                width: 14px;
                height: 14px;
                background-color: $mint;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
            }
        }
    }

}

.carusel_body_webp {
    @extend .carusel_body;
    background-image: url('../../../../public/5570863_.webp');
}

.box_job_text {
    @extend %sсreenplay;

}

.box_job_text.page_2 {
    @include page(-980px);
    @extend %sсreenplay;

    @media only screen and (max-width: 1200px) {
        @include page(-780px);
    }

    @media only screen and (max-width: 960px) {
        @include page(-627px);
    }

    @media only screen and (max-width: 768px) {
        @include page(-480px);
    }

    @media only screen and (max-width: 620px) {
        @include page(-400px);
    }

    @media only screen and (max-width: 420px) {
        @include page(-300px);
    }

    @media only screen and (max-width: 340px) {
        @include page(-260px);
    }
}

.box_job_text.page_3 {
    @include page(-1960px);
    @extend %sсreenplay;

    @media only screen and (max-width: 1200px) {
        @include page(-1560px);
    }

    @media only screen and (max-width: 960px) {
        @include page(-1254px);
    }

    @media only screen and (max-width: 768px) {
        @include page(-960px);
    }

    @media only screen and (max-width: 620px) {
        @include page(-800px);
    }

    @media only screen and (max-width: 420px) {
        @include page(-600px);
    }

    @media only screen and (max-width: 340px) {
        @include page(-520px);
    }
}

.box_job_text.page_4 {
    @include page(-1440px);
    @extend %sсreenplay;

    @media only screen and (max-width: 620px) {
        @include page(-1200px);
    }

    @media only screen and (max-width: 420px) {
        @include page(-900px);
    }

    @media only screen and (max-width: 340px) {
        @include page(-780px);
    }
}

.box_job_text.page_5 {
    @include page(-1920px);
    @extend %sсreenplay;

    @media only screen and (max-width: 620px) {
        @include page(-1600px);
    }

    @media only screen and (max-width: 420px) {
        @include page(-1200px);
    }

    @media only screen and (max-width: 340px) {
        @include page(-1040px);
    }
}

.box_job_text.page_6 {
    @include page(-2400px);
    @extend %sсreenplay;

    @media only screen and (max-width: 620px) {
        @include page(-2000px);
    }

    @media only screen and (max-width: 420px) {
        @include page(-1500px);
    }

    @media only screen and (max-width: 340px) {
        @include page(-1300px);
    }
}

.education_container {
    background-color: $black;
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    overflow: hidden;
    margin-bottom: 30px;

    @media only screen and (max-width: 720px) {
        margin-bottom: 25px;
    }

    @media only screen and (max-width: 560px) {
        margin-bottom: 20px;
    }

    .university_container {
        display: flex;
        flex-direction: column;

        .university_title {
            box-sizing: border-box;
            display: flex;
            width: 65%;
            margin: auto;
            justify-content: center;

            .educationicon_box {
                margin-right: 20px;

                @media only screen and (max-width: 720px) {
                    margin-right: 10px;
                }

                @media only screen and (max-width: 380px) {
                    margin-right: 5px;
                }

                .educationicon {
                    margin: 0 10px;
                    width: 100px;
                    height: 100px;
                    fill: $mint;

                    @media only screen and (max-width: 960px) {
                        width: 75px;
                        height: 75px;

                    }

                    @media only screen and (max-width: 720px) {
                        width: 60px;
                        height: 60px;
                    }

                    @media only screen and (max-width: 560px) {
                        margin: 0 10px 10px;
                        width: 40px;
                        height: 40px;
                    }

                    @media only screen and (max-width: 380px) {
                        width: 30px;
                        height: 30px;
                    }
                }


            }

            .educationicon_box_hidden {
                opacity: 0;
            }

            .box_h2_educ {
                margin: 0 10px;
                color: $mint;
                @extend %h2_style;
                margin-top: 10px;
            }

            .box_h2_educ_hidden {
                opacity: 0;
            }

        }

        .title_line {
            border: $mint 1px solid;
            width: 60%;
            margin: 0 auto 15px;
        }

        .university_box {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            @media only screen and (max-width: 720px) {
                flex-direction: column;
            }

            .first_education {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                width: 50%;

                @media only screen and (max-width: 720px) {
                    width: 95%;
                }

                .title_first {
                    display: flex;

                    .digiticon_box {
                        .digiticon {
                            width: 50px;
                            height: 50px;
                            margin-left: 40px;
                            margin-right: 0;
                            fill: $mint;
                            padding: 30px 0 0 0;

                            @media only screen and (max-width: 960px) {
                                width: 45px;
                                height: 45px;
                            }

                            @media only screen and (max-width: 560px) {
                                width: 40px;
                                height: 40px;
                            }

                            @media only screen and (max-width: 480px) {
                                width: 35px;
                                height: 35px;
                            }

                            @media only screen and (max-width: 380px) {
                                width: 30px;
                                height: 30px;
                            }

                        }
                    }

                    .digiticon_box_hidden {
                        opacity: 0;
                    }

                    .box_h3 {
                        @extend %h3_style;
                        color: $white;
                        margin: 0 50px;
                        padding: 0;

                        h3 {
                            margin: 20px 0 10px 0;
                        }

                        span {
                            @extend %small_description;
                            color: $mint;
                            padding: 0;
                        }

                    }
                }






                .description {
                    .item_education {
                        @extend %small_description;
                        color: white;
                        margin: 0 50px;

                        p {
                            margin: 10px 0 0 0;

                            &:nth-child(even) {
                                margin-left: 20px;
                                @extend %small_description;
                                color: $mint;
                                padding: 0;

                                @media only screen and (max-width: 960px) {
                                    margin: 0 40px;
                                }

                                @media only screen and (max-width: 560px) {
                                    margin: 0 5px 0 25px;
                                }

                                @media only screen and (max-width: 480px) {
                                    margin: 0 5px 0 20px;
                                }
                            }

                            &:nth-child(odd):before {
                                content: '< >';
                                margin-right: 10px;
                            }

                            @media only screen and (max-width: 960px) {
                                margin: 0 40px;
                            }

                            @media only screen and (max-width: 560px) {
                                margin: 0 5px 0 25px;
                            }

                            @media only screen and (max-width: 480px) {
                                margin: 0 5px 0 20px;
                            }
                        }
                    }


                    .date_item {
                        @extend %small_description;
                        color: $mint;
                        margin: 0 80px;
                        padding: 0;

                        @media only screen and (max-width: 960px) {
                            margin: 0 60px;
                        }

                        @media only screen and (max-width: 560px) {
                            margin: 0 40px;
                        }

                        @media only screen and (max-width: 480px) {
                            font-size: 12px;
                            margin: 0 30px;

                        }
                    }
                }
            }

            .second_education {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                width: 50%;

                @media only screen and (max-width: 720px) {
                    width: 95%;
                }

                .title_second {
                    display: flex;

                    .digiticon_box_two {
                        .digiticon {
                            width: 50px;
                            height: 50px;
                            margin-left: 40px;
                            margin-right: 0;
                            fill: $mint;
                            padding: 30px 0 0 0;

                            @media only screen and (max-width: 960px) {
                                width: 45px;
                                height: 45px;
                            }

                            @media only screen and (max-width: 560px) {
                                width: 40px;
                                height: 40px;
                            }

                            @media only screen and (max-width: 480px) {
                                width: 35px;
                                height: 35px;
                            }

                            @media only screen and (max-width: 380px) {
                                width: 30px;
                                height: 30px;
                            }

                        }
                    }

                    .digiticon_box_two_hidden {
                        opacity: 0;
                    }

                    .box_h3 {
                        @extend %h3_style;
                        color: $white;
                        margin: 0 50px;
                        padding: 0;

                        h3 {
                            margin: 20px 0 0 0;
                        }

                        @media only screen and (max-width: 960px) {
                            margin: 0 30px;
                        }

                        @media only screen and (max-width: 560px) {
                            margin: 0 20px;
                        }

                        @media only screen and (max-width: 480px) {
                            margin: 0 10px;
                        }

                        span {
                            @extend %small_description;
                            color: $mint;
                            padding: 0;
                        }
                    }
                }
            }
        }

        .courses_box {
            display: flex;
            flex-direction: column;
            width: 60%;
            margin: auto;

            @media only screen and (max-width: 960px) {
                width: 75%;
            }

            @media only screen and (max-width: 720px) {
                width: 85%;
            }

            .courses_title {
                display: inline-block;
                margin: 0 auto;
                color: $mint;
                @extend %h3_style;

                h3 {
                    margin-bottom: 10px;
                }
            }

            .courses_description {
                display: flex;
                flex-direction: column;

                .row {
                    display: flex;
                    margin-bottom: 5px;
                    align-items: center;

                    .dot_box {
                        margin-right: 10px;

                        .dot {
                            background-color: $mint;
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            -webkit-border-radius: 50%;
                            -moz-border-radius: 50%;
                            -ms-border-radius: 50%;
                            -o-border-radius: 50%;
                        }
                    }

                    .courses_item {
                        @extend %big_description;
                        color: $white;
                        margin: 0 50px 10px 50px;
                        padding: 0;

                        @media only screen and (max-width: 960px) {
                            margin: 0 30px;
                        }

                        @media only screen and (max-width: 560px) {
                            margin: 0 20px;
                        }

                        @media only screen and (max-width: 480px) {
                            margin: 0 10px;
                        }

                        span {
                            @extend %small_description;
                            color: $mint;
                        }
                    }
                }

                .examples {
                    width: 60%;
                    @extend %big_description;
                    color: $white;
                    margin: 0 0 0 90px;
                    padding: 0;

                    @media only screen and (max-width: 560px) {
                        margin: 0 0 0 10px;
                        width: 80%;

                    }

                    @media only screen and (max-width: 480px) {}

                    p {
                        a {
                            @extend %small_description;
                            color: $mint;
                            padding: 0;
                            margin-bottom: 5px;
                        }

                    }

                    h4 {
                        margin: 0 0 10px 90px;
                    }

                    @media only screen and (max-width: 960px) {
                        margin: 0 0 0 30px;
                    }

                    @media only screen and (max-width: 560px) {
                        margin: 0 0 0 20px;
                    }

                    @media only screen and (max-width: 480px) {
                        margin: 0 0 0 10px;
                    }
                }
            }
        }
    }




}

.soft_skills_container {
    max-width: 1200px;
    margin: 0 auto 100px auto;
    overflow: hidden;

    .soft_skills_title {
        margin: 0 10px 20px 10px;
        color: $mint;
        @extend %h2_style;
        display: flex;
        justify-content: center;

        h2 {
            margin: auto;
            display: block;
        }
    }



    @media only screen and (max-width: 560px) {
        width: 300px;
        margin: 0 auto 30px auto;
    }

    @media only screen and (max-width: 480px) {
        width: 300px;
    }

    @media only screen and (max-width: 320px) {
        width: 270px;
    }

    .soft_skills_box {
        width: 100%;
        display: grid;
        grid-template-columns: 33.3% 33.3% 33.3%;
        grid-template-rows: 160px 160px 160px;

        @media only screen and (max-width: 560px) {
            grid-template-columns: 100%;
            grid-template-rows: repeat(9, 45px);
        }

        .item_1,
        .item_2,
        .item_3,
        .item_4,
        .item_5,
        .item_6,
        .item_7,
        .item_8,
        .item_9 {
            box-sizing: border-box;
            @extend %big_description;
            color: $graphite;
            margin: 0 50px;


            @media only screen and (max-width: 960px) {
                font-size: 17px;
            }

            @media only screen and (max-width: 720px) {
                font-size: 16px;
            }

            @media only screen and (max-width: 560px) {
                font-size: 15px;

            }

            @media only screen and (max-width: 480px) {
                font-size: 15px;
            }

            @media only screen and (max-width: 380px) {
                font-size: 14px;

            }

        }

        .item_1 {
            padding-top: 30px;
            text-align: center;

            @media only screen and (max-width: 560px) {
                text-align: start;
                padding: 0;
                margin: 0;

                p {
                    line-height: 24px;

                    &::before {
                        content: '①';
                        color: $mint;
                        font-size: $size_numbers;
                        margin-right: $margin_numbers_right;
                        margin-top: $margin_numbers_top;
                    }
                }
            }
        }



        .item_2 {
            text-align: center;

            @media only screen and (max-width: 560px) {
                text-align: start;
                padding: 0;
                margin: 0;

                p {
                    line-height: 24px;

                    &::before {
                        content: '②';
                        color: $mint;
                        font-size: $size_numbers;
                        margin-right: $margin_numbers_right;
                        margin-top: $margin_numbers_top;
                    }
                }
            }
        }

        .item_3 {
            padding-top: 30px;
            text-align: center;

            @media only screen and (max-width: 560px) {
                text-align: start;
                padding: 0;
                margin: 0;

                p {
                    line-height: 24px;

                    &::before {
                        content: '③';
                        color: $mint;
                        font-size: $size_numbers;
                        margin-right: $margin_numbers_right;
                        margin-top: $margin_numbers_top;
                    }
                }
            }


        }

        .item_4 {
            padding-top: 30px;

            @media only screen and (max-width: 560px) {
                text-align: start;
                padding: 0;
                margin: 0;

                p {
                    line-height: 24px;

                    &::before {
                        content: '④';
                        color: $mint;
                        font-size: $size_numbers;
                        margin-right: $margin_numbers_right;
                        margin-top: $margin_numbers_top;
                    }
                }
            }
        }

        .item_5 {

            @media only screen and (max-width: 560px) {
                grid-row-start: 1;
                grid-row-end: 3;
            }


            .softskillsicon {
                width: 150px;
                height: 150px;

                @media only screen and (max-width: 960px) {
                    width: 130px;
                    height: 130px;
                }

                @media only screen and (max-width: 720px) {
                    width: 100px;
                    height: 100px;
                }

                @media only screen and (max-width: 560px) {
                    width: 80px;
                    height: 80px;

                }

                @media only screen and (max-width: 480px) {
                    width: 70px;
                    height: 70px;
                }

                @media only screen and (max-width: 380px) {
                    font-size: 60px;
                }
            }

            text-align: center;
            box-sizing: border-box;

        }

        .item_6 {
            padding-top: 30px;
            text-align: end;

            @media only screen and (max-width: 560px) {
                text-align: start;
                padding: 0;
                margin: 0;

                p {
                    line-height: 24px;

                    &::before {
                        content: '⑤';
                        color: $mint;
                        font-size: $size_numbers;
                        margin-right: $margin_numbers_right;
                        margin-top: $margin_numbers_top;
                    }
                }
            }
        }

        .item_7 {
            padding-top: 20px;
            text-align: center;

            @media only screen and (max-width: 560px) {
                text-align: start;
                padding: 0;
                margin: 0;

                p {
                    line-height: 24px;

                    &::before {
                        content: '⑥';
                        color: $mint;
                        font-size: $size_numbers;
                        margin-right: $margin_numbers_right;
                        margin-top: $margin_numbers_top;
                    }
                }
            }
        }

        .item_8 {
            padding-top: 60px;
            text-align: center;

            @media only screen and (max-width: 560px) {
                text-align: start;
                padding: 0;
                margin: 0;

                p {
                    line-height: 24px;

                    &::before {
                        content: '⑦';
                        color: $mint;
                        font-size: $size_numbers;
                        margin-right: $margin_numbers_right;
                        margin-top: $margin_numbers_top;
                    }
                }
            }

            @media only screen and (max-width: 320px) {
                line-height: 28px;
            }
        }

        .item_9 {
            padding-top: 20px;
            text-align: center;

            @media only screen and (max-width: 560px) {
                text-align: start;
                padding: 0;
                margin: 0;

                p {
                    line-height: 24px;

                    &::before {
                        content: '⑧';
                        color: $mint;
                        font-size: $size_numbers;
                        margin-right: $margin_numbers_right;
                        margin-top: $margin_numbers_top;
                    }
                }
            }
        }
    }
}

.interest_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    .title_iterests_box {
        margin-bottom: 35px;
        @extend %h2_style;
    }

    .window_interest {
        display: flex;
        align-items: center;
        margin: 0 auto 20px;
        width: 1000px;
        height: 230px;
        overflow: hidden;

        @media only screen and (max-width: 960px) {
            width: 750px;

        }

        @media only screen and (max-width: 720px) {
            width: 540px;

        }

        @media only screen and (max-width: 560px) {
            width: 430px;

        }

        @media only screen and (max-width: 720px) {
            width: 300px;

        }

        .carusel_interests {
            display: flex;
            margin: auto;

            .interest_item_1,
            .interest_item_2,
            .interest_item_3,
            .interest_item_4,
            .interest_item_5 {
                transform: scale(0.3, 0.3);
                -webkit-transform: scale(0.3, 0.3);
                -moz-transform: scale(0.3, 0.3);
                -ms-transform: scale(0.3, 0.3);
                -o-transform: scale(0.3, 0.3);
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                margin: 0 15px;
                padding: 10px;
                align-items: center;
                width: 150px;
                height: 150px;
                border: $mint 1px solid;
                border-radius: 5px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -ms-border-radius: 5px;
                -o-border-radius: 5px;

                @media only screen and (max-width: 960px) {
                    width: 135px;
                    height: 135px;
                    margin: 0 5px;
                }


                .interest_icon_box {
                    fill: $mint;
                    margin-bottom: 10px;
                    width: 80px;
                    height: 80px;


                    .jogginicon,
                    .campingicon,
                    .fishingicon,
                    .cyclingicon,
                    .hikingicon {
                        width: 100%;
                        height: 100%;
                        margin: auto;
                    }

                    .hikingicon {
                        margin-left: 15px;
                    }
                }

                .title_interest {

                    h3 {
                        margin: 0;
                        color: $graphite;
                        @extend %big_description;
                    }
                }
            }

            .interest_item_1 {
                animation: interest_1 10s ease infinite;
                -webkit-animation: interest_1 10s ease infinite;
            }

            .interest_item_2 {
                animation: interest_2 10s ease 2s infinite;
                -webkit-animation: interest_2 10s ease 2s infinite;
            }

            .interest_item_3 {
                animation: interest_3 10s ease 4s infinite;
                -webkit-animation: interest_3 10s ease 4s infinite;
            }

            .interest_item_4 {
                animation: interest_4 10s ease 6s infinite;
                -webkit-animation: interest_4 10s ease 6s infinite;
            }

            .interest_item_5 {
                animation: interest_5 10s ease 8s infinite;
                -webkit-animation: interest_5 10s ease 8s infinite;
            }

            @keyframes interest_1 {
                0% {
                    opacity: 1;
                    transform: translate(700px) scale(0.3, 0.3);
                    -webkit-transform: translate(700px) scale(0.3, 0.3);
                    -moz-transform: translate(700px) scale(0.3, 0.3);
                    -ms-transform: translate(700px) scale(0.3, 0.3);
                    -o-transform: translate(700px) scale(0.3, 0.3);
                }

                25% {
                    opacity: 1;
                    transform: translate(350px) scale(1, 1);
                    -webkit-transform: translate(350px) scale(1, 1);
                    -moz-transform: translate(350px) scale(1, 1);
                    -ms-transform: translate(350px) scale(1, 1);
                    -o-transform: translate(350px) scale(1, 1);
                }

                50% {
                    opacity: 1;
                    transform: translate(0) scale(0.3, 0.3);
                    -webkit-transform: translate(0) scale(0.3, 0.3);
                    -moz-transform: translate(0) scale(0.3, 0.3);
                    -ms-transform: translate(0) scale(0.3, 0.3);
                    -o-transform: translate(0) scale(0.3, 0.3);
                }

                75% {
                    opacity: 0.5;
                    transform: translate(350px) scale(0.3, 0.3);
                    -webkit-transform: translate(350px) scale(0.3, 0.3);
                    -moz-transform: translate(350px) scale(0.3, 0.3);
                    -ms-transform: translate(350px) scale(0.3, 0.3);
                    -o-transform: translate(350px) scale(0.3, 0.3);
                }

                100% {
                    opacity: 0.5;
                    transform: translate(700px) scale(0.3, 0.3);
                    -webkit-transform: translate(700px) scale(0.3, 0.3);
                    -moz-transform: translate(700px) scale(0.3, 0.3);
                    -ms-transform: translate(700px) scale(0.3, 0.3);
                    -o-transform: translate(700px) scale(0.3, 0.3);
                }
            }

            @keyframes interest_2 {
                0% {
                    opacity: 1;
                    transform: translate(520px) scale(0.3, 0.3);
                    -webkit-transform: translate(520px) scale(0.3, 0.3);
                    -moz-transform: translate(520px) scale(0.3, 0.3);
                    -ms-transform: translate(520px) scale(0.3, 0.3);
                    -o-transform: translate(520px) scale(0.3, 0.3);
                }

                25% {
                    opacity: 1;
                    transform: translate(170px) scale(1, 1);
                    -webkit-transform: translate(170px) scale(1, 1);
                    -moz-transform: translate(170px) scale(1, 1);
                    -ms-transform: translate(170px) scale(1, 1);
                    -o-transform: translate(170px) scale(1, 1);
                }

                50% {
                    opacity: 1;
                    transform: translate(-180px) scale(0.3, 0.3);
                    -webkit-transform: translate(-180px) scale(0.3, 0.3);
                    -moz-transform: translate(-180px) scale(0.3, 0.3);
                    -ms-transform: translate(-180px) scale(0.3, 0.3);
                    -o-transform: translate(-180px) scale(0.3, 0.3);
                }

                75% {
                    opacity: 0.5;
                    transform: translate(180px) scale(0.3, 0.3);
                    -webkit-transform: translate(180px) scale(0.3, 0.3);
                    -moz-transform: translate(180px) scale(0.3, 0.3);
                    -ms-transform: translate(180px) scale(0.3, 0.3);
                    -o-transform: translate(180px) scale(0.3, 0.3);
                }

                100% {
                    opacity: 0.5;
                    transform: translate(520px) scale(0.3, 0.3);
                    -webkit-transform: translate(520px) scale(0.3, 0.3);
                    -moz-transform: translate(520px) scale(0.3, 0.3);
                    -ms-transform: translate(520px) scale(0.3, 0.3);
                    -o-transform: translate(520px) scale(0.3, 0.3);
                }
            }

            @keyframes interest_3 {
                0% {
                    opacity: 1;
                    transform: translate(340px) scale(0.3, 0.3);
                    -webkit-transform: translate(340px) scale(0.3, 0.3);
                    -moz-transform: translate(340px) scale(0.3, 0.3);
                    -ms-transform: translate(340px) scale(0.3, 0.3);
                    -o-transform: translate(340px) scale(0.3, 0.3);
                }

                25% {
                    opacity: 1;
                    transform: translate(-10px) scale(1, 1);
                    -webkit-transform: translate(-10px) scale(1, 1);
                    -moz-transform: translate(-10px) scale(1, 1);
                    -ms-transform: translate(-10px) scale(1, 1);
                    -o-transform: translate(-10px) scale(1, 1);
                }

                50% {
                    opacity: 1;
                    transform: translate(-360px) scale(0.3, 0.3);
                    -webkit-transform: translate(-360px) scale(0.3, 0.3);
                    -moz-transform: translate(-360px) scale(0.3, 0.3);
                    -ms-transform: translate(-360px) scale(0.3, 0.3);
                    -o-transform: translate(-360px) scale(0.3, 0.3);
                }

                75% {
                    opacity: 0.5;
                    transform: translate(10px) scale(0.3, 0.3);
                    -webkit-transform: translate(10px) scale(0.3, 0.3);
                    -moz-transform: translate(10px) scale(0.3, 0.3);
                    -ms-transform: translate(10px) scale(0.3, 0.3);
                    -o-transform: translate(10px) scale(0.3, 0.3);
                }

                100% {
                    opacity: 0.5;
                    transform: translate(340px) scale(0.3, 0.3);
                    -webkit-transform: translate(340px) scale(0.3, 0.3);
                    -moz-transform: translate(340px) scale(0.3, 0.3);
                    -ms-transform: translate(340px) scale(0.3, 0.3);
                    -o-transform: translate(340px) scale(0.3, 0.3);
                }
            }

            @keyframes interest_4 {
                0% {
                    opacity: 1;
                    transform: translate(160px) scale(0.3, 0.3);
                    -webkit-transform: translate(160px) scale(0.3, 0.3);
                    -moz-transform: translate(160px) scale(0.3, 0.3);
                    -ms-transform: translate(160px) scale(0.3, 0.3);
                    -o-transform: translate(160px) scale(0.3, 0.3);
                }

                25% {
                    opacity: 1;
                    transform: translate(-190px) scale(1, 1);
                    -webkit-transform: translate(-190px) scale(1, 1);
                    -moz-transform: translate(-190px) scale(1, 1);
                    -ms-transform: translate(-190px) scale(1, 1);
                    -o-transform: translate(-190px) scale(1, 1);
                }

                50% {
                    opacity: 1;
                    transform: translate(-540px) scale(0.3, 0.3);
                    -webkit-transform: translate(-540px) scale(0.3, 0.3);
                    -moz-transform: translate(-540px) scale(0.3, 0.3);
                    -ms-transform: translate(-540px) scale(0.3, 0.3);
                    -o-transform: translate(-540px) scale(0.3, 0.3);
                }

                75% {
                    opacity: 0.5;
                    transform: translate(-190px) scale(0.3, 0.3);
                    -webkit-transform: translate(-190px) scale(0.3, 0.3);
                    -moz-transform: translate(-190px) scale(0.3, 0.3);
                    -ms-transform: translate(-190px) scale(0.3, 0.3);
                    -o-transform: translate(-190px) scale(0.3, 0.3);
                }

                100% {
                    opacity: 0.5;
                    transform: translate(160px) scale(0.3, 0.3);
                    -webkit-transform: translate(160px) scale(0.3, 0.3);
                    -moz-transform: translate(160px) scale(0.3, 0.3);
                    -ms-transform: translate(160px) scale(0.3, 0.3);
                    -o-transform: translate(160px) scale(0.3, 0.3);
                }
            }

            @keyframes interest_5 {
                0% {
                    opacity: 1;
                    transform: translate(-20px) scale(0.3, 0.3);
                    -webkit-transform: translate(-20px) scale(0.3, 0.3);
                    -moz-transform: translate(-20px) scale(0.3, 0.3);
                    -ms-transform: translate(-20px) scale(0.3, 0.3);
                    -o-transform: translate(-20px) scale(0.3, 0.3);
                }

                25% {
                    opacity: 1;
                    transform: translate(-370px) scale(1, 1);
                    -webkit-transform: translate(-370px) scale(1, 1);
                    -moz-transform: translate(-370px) scale(1, 1);
                    -ms-transform: translate(-370px) scale(1, 1);
                    -o-transform: translate(-370px) scale(1, 1);
                }

                50% {
                    opacity: 1;
                    transform: translate(-720px) scale(0.3, 0.3);
                    -webkit-transform: translate(-720px) scale(0.3, 0.3);
                    -moz-transform: translate(-720px) scale(0.3, 0.3);
                    -ms-transform: translate(-720px) scale(0.3, 0.3);
                    -o-transform: translate(-720px) scale(0.3, 0.3);
                }

                75% {
                    opacity: 0.5;
                    transform: translate(-370px) scale(0.3, 0.3);
                    -webkit-transform: translate(-370px) scale(0.3, 0.3);
                    -moz-transform: translate(-370px) scale(0.3, 0.3);
                    -ms-transform: translate(-370px) scale(0.3, 0.3);
                    -o-transform: translate(-370px) scale(0.3, 0.3);
                }

                100% {
                    opacity: 0.5;
                    transform: translate(-20px) scale(0.3, 0.3);
                    -webkit-transform: translate(-20px) scale(0.3, 0.3);
                    -moz-transform: translate(-20px) scale(0.3, 0.3);
                    -ms-transform: translate(-20px) scale(0.3, 0.3);
                    -o-transform: translate(-20px) scale(0.3, 0.3);
                }
            }


            @media only screen and (max-width: 960px) {
                @keyframes interest_1 {
                    0% {
                        opacity: 1;
                        transform: translate(600px) scale(0.3, 0.3);
                        -webkit-transform: translate(600px) scale(0.3, 0.3);
                        -moz-transform: translate(600px) scale(0.3, 0.3);
                        -ms-transform: translate(600px) scale(0.3, 0.3);
                        -o-transform: translate(600px) scale(0.3, 0.3);
                    }

                    25% {
                        opacity: 1;
                        transform: translate(300px) scale(1, 1);
                        -webkit-transform: translate(300px) scale(1, 1);
                        -moz-transform: translate(300px) scale(1, 1);
                        -ms-transform: translate(300px) scale(1, 1);
                        -o-transform: translate(300px) scale(1, 1);
                    }

                    50% {
                        opacity: 1;
                        transform: translate(0) scale(0.3, 0.3);
                        -webkit-transform: translate(0) scale(0.3, 0.3);
                        -moz-transform: translate(0) scale(0.3, 0.3);
                        -ms-transform: translate(0) scale(0.3, 0.3);
                        -o-transform: translate(0) scale(0.3, 0.3);
                    }

                    75% {
                        opacity: 0.5;
                        transform: translate(300px) scale(0.3, 0.3);
                        -webkit-transform: translate(300px) scale(0.3, 0.3);
                        -moz-transform: translate(300px) scale(0.3, 0.3);
                        -ms-transform: translate(300px) scale(0.3, 0.3);
                        -o-transform: translate(300px) scale(0.3, 0.3);
                    }

                    100% {
                        opacity: 0.5;
                        transform: translate(600px) scale(0.3, 0.3);
                        -webkit-transform: translate(600px) scale(0.3, 0.3);
                        -moz-transform: translate(600px) scale(0.3, 0.3);
                        -ms-transform: translate(600px) scale(0.3, 0.3);
                        -o-transform: translate(600px) scale(0.3, 0.3);
                    }
                }


                @keyframes interest_2 {
                    0% {
                        opacity: 1;
                        transform: translate(455px) scale(0.3, 0.3);
                        -webkit-transform: translate(455px) scale(0.3, 0.3);
                        -moz-transform: translate(455px) scale(0.3, 0.3);
                        -ms-transform: translate(455px) scale(0.3, 0.3);
                        -o-transform: translate(455px) scale(0.3, 0.3);
                    }

                    25% {
                        opacity: 1;
                        transform: translate(155px) scale(1, 1);
                        -webkit-transform: translate(155px) scale(1, 1);
                        -moz-transform: translate(155px) scale(1, 1);
                        -ms-transform: translate(155px) scale(1, 1);
                        -o-transform: translate(155px) scale(1, 1);
                    }

                    50% {
                        opacity: 1;
                        transform: translate(-145px) scale(0.3, 0.3);
                        -webkit-transform: translate(-145px) scale(0.3, 0.3);
                        -moz-transform: translate(-145px) scale(0.3, 0.3);
                        -ms-transform: translate(-145px) scale(0.3, 0.3);
                        -o-transform: translate(-145px) scale(0.3, 0.3);
                    }

                    75% {
                        opacity: 0.5;
                        transform: translate(155px) scale(0.3, 0.3);
                        -webkit-transform: translate(155px) scale(0.3, 0.3);
                        -moz-transform: translate(155px) scale(0.3, 0.3);
                        -ms-transform: translate(155px) scale(0.3, 0.3);
                        -o-transform: translate(155px) scale(0.3, 0.3);
                    }

                    100% {
                        opacity: 0.5;
                        transform: translate(455px) scale(0.3, 0.3);
                        -webkit-transform: translate(455px) scale(0.3, 0.3);
                        -moz-transform: translate(455px) scale(0.3, 0.3);
                        -ms-transform: translate(455px) scale(0.3, 0.3);
                        -o-transform: translate(455px) scale(0.3, 0.3);
                    }

                }

                @keyframes interest_3 {
                    0% {
                        opacity: 1;
                        transform: translate(310px) scale(0.3, 0.3);
                        -webkit-transform: translate(310px) scale(0.3, 0.3);
                        -moz-transform: translate(310px) scale(0.3, 0.3);
                        -ms-transform: translate(310px) scale(0.3, 0.3);
                        -o-transform: translate(310px) scale(0.3, 0.3);
                    }

                    25% {
                        opacity: 1;
                        transform: translate(10px) scale(1, 1);
                        -webkit-transform: translate(10px) scale(1, 1);
                        -moz-transform: translate(10px) scale(1, 1);
                        -ms-transform: translate(10px) scale(1, 1);
                        -o-transform: translate(10px) scale(1, 1);
                    }

                    50% {
                        opacity: 1;
                        transform: translate(-290px) scale(0.3, 0.3);
                        -webkit-transform: translate(-290px) scale(0.3, 0.3);
                        -moz-transform: translate(-290px) scale(0.3, 0.3);
                        -ms-transform: translate(-290px) scale(0.3, 0.3);
                        -o-transform: translate(-290px) scale(0.3, 0.3);
                    }

                    75% {
                        opacity: 0.5;
                        transform: translate(10px) scale(0.3, 0.3);
                        -webkit-transform: translate(10px) scale(0.3, 0.3);
                        -moz-transform: translate(10px) scale(0.3, 0.3);
                        -ms-transform: translate(10px) scale(0.3, 0.3);
                        -o-transform: translate(10px) scale(0.3, 0.3);
                    }

                    100% {
                        opacity: 0.5;
                        transform: translate(310px) scale(0.3, 0.3);
                        -webkit-transform: translate(310px) scale(0.3, 0.3);
                        -moz-transform: translate(310px) scale(0.3, 0.3);
                        -ms-transform: translate(310px) scale(0.3, 0.3);
                        -o-transform: translate(310px) scale(0.3, 0.3);
                    }
                }

                @keyframes interest_4 {
                    0% {
                        opacity: 1;
                        transform: translate(165px) scale(0.3, 0.3);
                        -webkit-transform: translate(165px) scale(0.3, 0.3);
                        -moz-transform: translate(165px) scale(0.3, 0.3);
                        -ms-transform: translate(165px) scale(0.3, 0.3);
                        -o-transform: translate(165px) scale(0.3, 0.3);
                    }

                    25% {
                        opacity: 1;
                        transform: translate(-135px) scale(1, 1);
                        -webkit-transform: translate(-135px) scale(1, 1);
                        -moz-transform: translate(-135px) scale(1, 1);
                        -ms-transform: translate(-135px) scale(1, 1);
                        -o-transform: translate(-135px) scale(1, 1);
                    }

                    50% {
                        opacity: 1;
                        transform: translate(-435px) scale(0.3, 0.3);
                        -webkit-transform: translate(-435px) scale(0.3, 0.3);
                        -moz-transform: translate(-435px) scale(0.3, 0.3);
                        -ms-transform: translate(-435px) scale(0.3, 0.3);
                        -o-transform: translate(-435px) scale(0.3, 0.3);
                    }

                    75% {
                        opacity: 0.5;
                        transform: translate(-135px) scale(0.3, 0.3);
                        -webkit-transform: translate(-135px) scale(0.3, 0.3);
                        -moz-transform: translate(-135px) scale(0.3, 0.3);
                        -ms-transform: translate(-135px) scale(0.3, 0.3);
                        -o-transform: translate(-135px) scale(0.3, 0.3);
                    }

                    100% {
                        opacity: 0.5;
                        transform: translate(165px) scale(0.3, 0.3);
                        -webkit-transform: translate(165px) scale(0.3, 0.3);
                        -moz-transform: translate(165px) scale(0.3, 0.3);
                        -ms-transform: translate(165px) scale(0.3, 0.3);
                        -o-transform: translate(165px) scale(0.3, 0.3);
                    }
                }

                @keyframes interest_5 {
                    0% {
                        opacity: 1;
                        transform: translate(-20px) scale(0.3, 0.3);
                        -webkit-transform: translate(-20px) scale(0.3, 0.3);
                        -moz-transform: translate(-20px) scale(0.3, 0.3);
                        -ms-transform: translate(-20px) scale(0.3, 0.3);
                        -o-transform: translate(-20px) scale(0.3, 0.3);
                    }

                    25% {
                        opacity: 1;
                        transform: translate(-280px) scale(1, 1);
                        -webkit-transform: translate(-280px) scale(1, 1);
                        -moz-transform: translate(-280px) scale(1, 1);
                        -ms-transform: translate(-280px) scale(1, 1);
                        -o-transform: translate(-280px) scale(1, 1);
                    }

                    50% {
                        opacity: 1;
                        transform: translate(-425px) scale(0.3, 0.3);
                        -webkit-transform: translate(-425px) scale(0.3, 0.3);
                        -moz-transform: translate(-425px) scale(0.3, 0.3);
                        -ms-transform: translate(-425px) scale(0.3, 0.3);
                        -o-transform: translate(-425px) scale(0.3, 0.3);
                    }

                    75% {
                        opacity: 0.5;
                        transform: translate(-280px) scale(0.3, 0.3);
                        -webkit-transform: translate(-280px) scale(0.3, 0.3);
                        -moz-transform: translate(-280px) scale(0.3, 0.3);
                        -ms-transform: translate(-280px) scale(0.3, 0.3);
                        -o-transform: translate(-280px) scale(0.3, 0.3);
                    }

                    100% {
                        opacity: 0.5;
                        transform: translate(-20px) scale(0.3, 0.3);
                        -webkit-transform: translate(-20px) scale(0.3, 0.3);
                        -moz-transform: translate(-20px) scale(0.3, 0.3);
                        -ms-transform: translate(-20px) scale(0.3, 0.3);
                        -o-transform: translate(-20px) scale(0.3, 0.3);
                    }
                }
            }

            @media only screen and (max-width: 720px) {
                @keyframes interest_1 {
                    0% {
                        opacity: 1;
                        transform: translate(400px) scale(0.3, 0.3);
                        -webkit-transform: translate(400px) scale(0.3, 0.3);
                        -moz-transform: translate(400px) scale(0.3, 0.3);
                        -ms-transform: translate(400px) scale(0.3, 0.3);
                        -o-transform: translate(400px) scale(0.3, 0.3);
                    }

                    25% {
                        opacity: 1;
                        transform: translate(200px) scale(1, 1);
                        -webkit-transform: translate(200px) scale(1, 1);
                        -moz-transform: translate(200px) scale(1, 1);
                        -ms-transform: translate(200px) scale(1, 1);
                        -o-transform: translate(200px) scale(1, 1);
                    }

                    50% {
                        opacity: 1;
                        transform: translate(0) scale(0.3, 0.3);
                        -webkit-transform: translate(0) scale(0.3, 0.3);
                        -moz-transform: translate(0) scale(0.3, 0.3);
                        -ms-transform: translate(0) scale(0.3, 0.3);
                        -o-transform: translate(0) scale(0.3, 0.3);
                    }

                    75% {
                        opacity: 0.5;
                        transform: translate(200px) scale(0.3, 0.3);
                        -webkit-transform: translate(200px) scale(0.3, 0.3);
                        -moz-transform: translate(200px) scale(0.3, 0.3);
                        -ms-transform: translate(200px) scale(0.3, 0.3);
                        -o-transform: translate(200px) scale(0.3, 0.3);
                    }

                    100% {
                        opacity: 0.5;
                        transform: translate(400px) scale(0.3, 0.3);
                        -webkit-transform: translate(400px) scale(0.3, 0.3);
                        -moz-transform: translate(400px) scale(0.3, 0.3);
                        -ms-transform: translate(400px) scale(0.3, 0.3);
                        -o-transform: translate(400px) scale(0.3, 0.3);
                    }
                }


                @keyframes interest_2 {
                    0% {
                        opacity: 1;
                        transform: translate(255px) scale(0.3, 0.3);
                        -webkit-transform: translate(255px) scale(0.3, 0.3);
                        -moz-transform: translate(255px) scale(0.3, 0.3);
                        -ms-transform: translate(255px) scale(0.3, 0.3);
                        -o-transform: translate(255px) scale(0.3, 0.3);
                    }

                    25% {
                        opacity: 1;
                        transform: translate(55px) scale(1, 1);
                        -webkit-transform: translate(55px) scale(1, 1);
                        -moz-transform: translate(55px) scale(1, 1);
                        -ms-transform: translate(55px) scale(1, 1);
                        -o-transform: translate(55px) scale(1, 1);
                    }

                    50% {
                        opacity: 1;
                        transform: translate(-145px) scale(0.3, 0.3);
                        -webkit-transform: translate(-145px) scale(0.3, 0.3);
                        -moz-transform: translate(-145px) scale(0.3, 0.3);
                        -ms-transform: translate(-145px) scale(0.3, 0.3);
                        -o-transform: translate(-145px) scale(0.3, 0.3);
                    }

                    75% {
                        opacity: 0.5;
                        transform: translate(55px) scale(0.3, 0.3);
                        -webkit-transform: translate(55px) scale(0.3, 0.3);
                        -moz-transform: translate(55px) scale(0.3, 0.3);
                        -ms-transform: translate(55px) scale(0.3, 0.3);
                        -o-transform: translate(55px) scale(0.3, 0.3);
                    }

                    100% {
                        opacity: 0.5;
                        transform: translate(255px) scale(0.3, 0.3);
                        -webkit-transform: translate(255px) scale(0.3, 0.3);
                        -moz-transform: translate(255px) scale(0.3, 0.3);
                        -ms-transform: translate(255px) scale(0.3, 0.3);
                        -o-transform: translate(255px) scale(0.3, 0.3);
                    }

                }

                @keyframes interest_3 {
                    0% {
                        opacity: 1;
                        transform: translate(110px) scale(0.3, 0.3);
                        -webkit-transform: translate(110px) scale(0.3, 0.3);
                        -moz-transform: translate(110px) scale(0.3, 0.3);
                        -ms-transform: translate(110px) scale(0.3, 0.3);
                        -o-transform: translate(110px) scale(0.3, 0.3);
                    }

                    25% {
                        opacity: 1;
                        transform: translate(-90px) scale(1, 1);
                        -webkit-transform: translate(-90px) scale(1, 1);
                        -moz-transform: translate(-90px) scale(1, 1);
                        -ms-transform: translate(-90px) scale(1, 1);
                        -o-transform: translate(-90px) scale(1, 1);
                    }

                    50% {
                        opacity: 1;
                        transform: translate(-290px) scale(0.3, 0.3);
                        -webkit-transform: translate(-290px) scale(0.3, 0.3);
                        -moz-transform: translate(-290px) scale(0.3, 0.3);
                        -ms-transform: translate(-290px) scale(0.3, 0.3);
                        -o-transform: translate(-290px) scale(0.3, 0.3);
                    }

                    75% {
                        opacity: 0.5;
                        transform: translate(-90px) scale(0.3, 0.3);
                        -webkit-transform: translate(-90px) scale(0.3, 0.3);
                        -moz-transform: translate(-90px) scale(0.3, 0.3);
                        -ms-transform: translate(-90px) scale(0.3, 0.3);
                        -o-transform: translate(-90px) scale(0.3, 0.3);
                    }

                    100% {
                        opacity: 0.5;
                        transform: translate(110px) scale(0.3, 0.3);
                        -webkit-transform: translate(110px) scale(0.3, 0.3);
                        -moz-transform: translate(110px) scale(0.3, 0.3);
                        -ms-transform: translate(110px) scale(0.3, 0.3);
                        -o-transform: translate(110px) scale(0.3, 0.3);
                    }
                }

                @keyframes interest_4 {
                    0% {
                        opacity: 1;
                        transform: translate(-35px) scale(0.3, 0.3);
                        -webkit-transform: translate(-35px) scale(0.3, 0.3);
                        -moz-transform: translate(-35px) scale(0.3, 0.3);
                        -ms-transform: translate(-35px) scale(0.3, 0.3);
                        -o-transform: translate(-35px) scale(0.3, 0.3);
                    }

                    25% {
                        opacity: 1;
                        transform: translate(-235px) scale(1, 1);
                        -webkit-transform: translate(-235px) scale(1, 1);
                        -moz-transform: translate(-235px) scale(1, 1);
                        -ms-transform: translate(-235px) scale(1, 1);
                        -o-transform: translate(-235px) scale(1, 1);
                    }

                    50% {
                        opacity: 1;
                        transform: translate(-435px) scale(0.3, 0.3);
                        -webkit-transform: translate(-435px) scale(0.3, 0.3);
                        -moz-transform: translate(-435px) scale(0.3, 0.3);
                        -ms-transform: translate(-435px) scale(0.3, 0.3);
                        -o-transform: translate(-435px) scale(0.3, 0.3);
                    }

                    75% {
                        opacity: 0.5;
                        transform: translate(-235px) scale(0.3, 0.3);
                        -webkit-transform: translate(-235px) scale(0.3, 0.3);
                        -moz-transform: translate(-235px) scale(0.3, 0.3);
                        -ms-transform: translate(-235px) scale(0.3, 0.3);
                        -o-transform: translate(-235px) scale(0.3, 0.3);
                    }

                    100% {
                        opacity: 0.5;
                        transform: translate(-35px) scale(0.3, 0.3);
                        -webkit-transform: translate(-35px) scale(0.3, 0.3);
                        -moz-transform: translate(-35px) scale(0.3, 0.3);
                        -ms-transform: translate(-35px) scale(0.3, 0.3);
                        -o-transform: translate(-35px) scale(0.3, 0.3);
                    }
                }

                @keyframes interest_5 {
                    0% {
                        opacity: 1;
                        transform: translate(-180px) scale(0.3, 0.3);
                        -webkit-transform: translate(-180px) scale(0.3, 0.3);
                        -moz-transform: translate(-180px) scale(0.3, 0.3);
                        -ms-transform: translate(-180px) scale(0.3, 0.3);
                        -o-transform: translate(-180px) scale(0.3, 0.3);
                    }

                    25% {
                        opacity: 1;
                        transform: translate(-380px) scale(1, 1);
                        -webkit-transform: translate(-380px) scale(1, 1);
                        -moz-transform: translate(-380px) scale(1, 1);
                        -ms-transform: translate(-380px) scale(1, 1);
                        -o-transform: translate(-380px) scale(1, 1);
                    }

                    50% {
                        opacity: 1;
                        transform: translate(-580px) scale(0.3, 0.3);
                        -webkit-transform: translate(-580px) scale(0.3, 0.3);
                        -moz-transform: translate(-580px) scale(0.3, 0.3);
                        -ms-transform: translate(-580px) scale(0.3, 0.3);
                        -o-transform: translate(-580px) scale(0.3, 0.3);
                    }

                    75% {
                        opacity: 0.5;
                        transform: translate(-380px) scale(0.3, 0.3);
                        -webkit-transform: translate(-380px) scale(0.3, 0.3);
                        -moz-transform: translate(-380px) scale(0.3, 0.3);
                        -ms-transform: translate(-380px) scale(0.3, 0.3);
                        -o-transform: translate(-380px) scale(0.3, 0.3);
                    }

                    100% {
                        opacity: 0.5;
                        transform: translate(-180px) scale(0.3, 0.3);
                        -webkit-transform: translate(-180px) scale(0.3, 0.3);
                        -moz-transform: translate(-180px) scale(0.3, 0.3);
                        -ms-transform: translate(-180px) scale(0.3, 0.3);
                        -o-transform: translate(-180px) scale(0.3, 0.3);
                    }


                }
            }

            @media only screen and (max-width: 560px) {
                @keyframes interest_1 {
                    0% {
                        opacity: 1;
                        transform: translate(270px) scale(0.3, 0.3);
                        -webkit-transform: translate(270px) scale(0.3, 0.3);
                        -moz-transform: translate(270px) scale(0.3, 0.3);
                        -ms-transform: translate(270px) scale(0.3, 0.3);
                        -o-transform: translate(270px) scale(0.3, 0.3);
                    }

                    25% {
                        opacity: 1;
                        transform: translate(70px) scale(1, 1);
                        -webkit-transform: translate(70px) scale(1, 1);
                        -moz-transform: translate(70px) scale(1, 1);
                        -ms-transform: translate(70px) scale(1, 1);
                        -o-transform: translate(70px) scale(1, 1);
                    }

                    50% {
                        opacity: 1;
                        transform: translate(-130px) scale(0.3, 0.3);
                        -webkit-transform: translate(-130px) scale(0.3, 0.3);
                        -moz-transform: translate(-130px) scale(0.3, 0.3);
                        -ms-transform: translate(-130px) scale(0.3, 0.3);
                        -o-transform: translate(-130px) scale(0.3, 0.3);
                    }

                    75% {
                        opacity: 0.5;
                        transform: translate(70px) scale(0.3, 0.3);
                        -webkit-transform: translate(70px) scale(0.3, 0.3);
                        -moz-transform: translate(70px) scale(0.3, 0.3);
                        -ms-transform: translate(70px) scale(0.3, 0.3);
                        -o-transform: translate(70px) scale(0.3, 0.3);
                    }

                    100% {
                        opacity: 0.5;
                        transform: translate(270px) scale(0.3, 0.3);
                        -webkit-transform: translate(270px) scale(0.3, 0.3);
                        -moz-transform: translate(270px) scale(0.3, 0.3);
                        -ms-transform: translate(270px) scale(0.3, 0.3);
                        -o-transform: translate(270px) scale(0.3, 0.3);
                    }


                }


                @keyframes interest_2 {
                    0% {
                        opacity: 1;
                        transform: translate(135px) scale(0.3, 0.3);
                        -webkit-transform: translate(135px) scale(0.3, 0.3);
                        -moz-transform: translate(135px) scale(0.3, 0.3);
                        -ms-transform: translate(135px) scale(0.3, 0.3);
                        -o-transform: translate(135px) scale(0.3, 0.3);
                    }

                    25% {
                        opacity: 1;
                        transform: translate(-65px) scale(1, 1);
                        -webkit-transform: translate(-65px) scale(1, 1);
                        -moz-transform: translate(-65px) scale(1, 1);
                        -ms-transform: translate(-65px) scale(1, 1);
                        -o-transform: translate(-65px) scale(1, 1);
                    }

                    50% {
                        opacity: 1;
                        transform: translate(-265px) scale(0.3, 0.3);
                        -webkit-transform: translate(-265px) scale(0.3, 0.3);
                        -moz-transform: translate(-265px) scale(0.3, 0.3);
                        -ms-transform: translate(-265px) scale(0.3, 0.3);
                        -o-transform: translate(-265px) scale(0.3, 0.3);
                    }

                    75% {
                        opacity: 0.5;
                        transform: translate(-65px) scale(0.3, 0.3);
                        -webkit-transform: translate(-65px) scale(0.3, 0.3);
                        -moz-transform: translate(-65px) scale(0.3, 0.3);
                        -ms-transform: translate(-65px) scale(0.3, 0.3);
                        -o-transform: translate(-65px) scale(0.3, 0.3);
                    }

                    100% {
                        opacity: 0.5;
                        transform: translate(135px) scale(0.3, 0.3);
                        -webkit-transform: translate(135px) scale(0.3, 0.3);
                        -moz-transform: translate(135px) scale(0.3, 0.3);
                        -ms-transform: translate(135px) scale(0.3, 0.3);
                        -o-transform: translate(135px) scale(0.3, 0.3);
                    }

                }

                @keyframes interest_3 {
                    0% {
                        opacity: 1;
                        transform: translate(-10px) scale(0.3, 0.3);
                        -webkit-transform: translate(-10px) scale(0.3, 0.3);
                        -moz-transform: translate(-10px) scale(0.3, 0.3);
                        -ms-transform: translate(-10px) scale(0.3, 0.3);
                        -o-transform: translate(-10px) scale(0.3, 0.3);
                    }

                    25% {
                        opacity: 1;
                        transform: translate(-210px) scale(1, 1);
                        -webkit-transform: translate(-210px) scale(1, 1);
                        -moz-transform: translate(-210px) scale(1, 1);
                        -ms-transform: translate(-210px) scale(1, 1);
                        -o-transform: translate(-210px) scale(1, 1);
                    }

                    50% {
                        opacity: 1;
                        transform: translate(-410px) scale(0.3, 0.3);
                        -webkit-transform: translate(-410px) scale(0.3, 0.3);
                        -moz-transform: translate(-410px) scale(0.3, 0.3);
                        -ms-transform: translate(-410px) scale(0.3, 0.3);
                        -o-transform: translate(-410px) scale(0.3, 0.3);
                    }

                    75% {
                        opacity: 0.5;
                        transform: translate(-210px) scale(0.3, 0.3);
                        -webkit-transform: translate(-210px) scale(0.3, 0.3);
                        -moz-transform: translate(-210px) scale(0.3, 0.3);
                        -ms-transform: translate(-210px) scale(0.3, 0.3);
                        -o-transform: translate(-210px) scale(0.3, 0.3);
                    }

                    100% {
                        opacity: 0.5;
                        transform: translate(-10px) scale(0.3, 0.3);
                        -webkit-transform: translate(-10px) scale(0.3, 0.3);
                        -moz-transform: translate(-10px) scale(0.3, 0.3);
                        -ms-transform: translate(-10px) scale(0.3, 0.3);
                        -o-transform: translate(-10px) scale(0.3, 0.3);
                    }
                }

                @keyframes interest_4 {
                    0% {
                        opacity: 1;
                        transform: translate(-155px) scale(0.3, 0.3);
                        -webkit-transform: translate(-155px) scale(0.3, 0.3);
                        -moz-transform: translate(-155px) scale(0.3, 0.3);
                        -ms-transform: translate(-155px) scale(0.3, 0.3);
                        -o-transform: translate(-155px) scale(0.3, 0.3);
                    }

                    25% {
                        opacity: 1;
                        transform: translate(-355px) scale(1, 1);
                        -webkit-transform: translate(-355px) scale(1, 1);
                        -moz-transform: translate(-355px) scale(1, 1);
                        -ms-transform: translate(-355px) scale(1, 1);
                        -o-transform: translate(-355px) scale(1, 1);
                    }

                    50% {
                        opacity: 1;
                        transform: translate(-555px) scale(0.3, 0.3);
                        -webkit-transform: translate(-555px) scale(0.3, 0.3);
                        -moz-transform: translate(-555px) scale(0.3, 0.3);
                        -ms-transform: translate(-555px) scale(0.3, 0.3);
                        -o-transform: translate(-555px) scale(0.3, 0.3);
                    }

                    75% {
                        opacity: 0.5;
                        transform: translate(-355px) scale(0.3, 0.3);
                        -webkit-transform: translate(-355px) scale(0.3, 0.3);
                        -moz-transform: translate(-355px) scale(0.3, 0.3);
                        -ms-transform: translate(-355px) scale(0.3, 0.3);
                        -o-transform: translate(-355px) scale(0.3, 0.3);
                    }

                    100% {
                        opacity: 0.5;
                        transform: translate(-155px) scale(0.3, 0.3);
                        -webkit-transform: translate(-155px) scale(0.3, 0.3);
                        -moz-transform: translate(-155px) scale(0.3, 0.3);
                        -ms-transform: translate(-155px) scale(0.3, 0.3);
                        -o-transform: translate(-155px) scale(0.3, 0.3);
                    }
                }

                @keyframes interest_5 {
                    0% {
                        opacity: 1;
                        transform: translate(-300px) scale(0.3, 0.3);
                        -webkit-transform: translate(-300px) scale(0.3, 0.3);
                        -moz-transform: translate(-300px) scale(0.3, 0.3);
                        -ms-transform: translate(-300px) scale(0.3, 0.3);
                        -o-transform: translate(-300px) scale(0.3, 0.3);
                    }

                    25% {
                        opacity: 1;
                        transform: translate(-500px) scale(1, 1);
                        -webkit-transform: translate(-500px) scale(1, 1);
                        -moz-transform: translate(-500px) scale(1, 1);
                        -ms-transform: translate(-500px) scale(1, 1);
                        -o-transform: translate(-500px) scale(1, 1);
                    }

                    50% {
                        opacity: 1;
                        transform: translate(-700px) scale(0.3, 0.3);
                        -webkit-transform: translate(-700px) scale(0.3, 0.3);
                        -moz-transform: translate(-700px) scale(0.3, 0.3);
                        -ms-transform: translate(-700px) scale(0.3, 0.3);
                        -o-transform: translate(-700px) scale(0.3, 0.3);
                    }

                    75% {
                        opacity: 0.5;
                        transform: translate(-500px) scale(0.3, 0.3);
                        -webkit-transform: translate(-500px) scale(0.3, 0.3);
                        -moz-transform: translate(-500px) scale(0.3, 0.3);
                        -ms-transform: translate(-500px) scale(0.3, 0.3);
                        -o-transform: translate(-500px) scale(0.3, 0.3);
                    }

                    100% {
                        opacity: 0.5;
                        transform: translate(-300px) scale(0.3, 0.3);
                        -webkit-transform: translate(-300px) scale(0.3, 0.3);
                        -moz-transform: translate(-300px) scale(0.3, 0.3);
                        -ms-transform: translate(-300px) scale(0.3, 0.3);
                        -o-transform: translate(-300px) scale(0.3, 0.3);
                    }


                }
            }
        }
    }
}

.box_to_top {
    position: fixed;
    z-index: 3;
    cursor: pointer;
    bottom: 2vh;
    right: 2vw;
    background-color: $blur_mint;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    box-shadow: 0 0 10px 3px rgba(221, 221, 221, 1);

    .totopicon_box {
        display: flex;
        margin-top: 5px;
        justify-content: center;

        .totopicon {
            width: 40px;
            height: 40px;
            fill: $white;
        }
    }
}

.box_to_top_hidden {
    @extend .box_to_top;
    opacity: 0;
    cursor: default;
}