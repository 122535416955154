@import '../../../constants/styles/null_file.scss';
@import '../../../constants/styles/variables.scss';

.menu {
    max-width: 100%;
    margin: 0 10px;
    padding: 15px 10px;
    height: 40px;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 760px) {
        margin-right: 0;
    }

    .logo {
        width: 20%;
        margin-top: 15px;

        img {
            width: 90px;
            height: 30px;

            @media only screen and(max-width: 960px) {
                width: 80px;
                height: 26px;
            }

            @media only screen and(max-width: 360px) {
                width: 70px;
                height: 23px;
            }
        }
    }

    .menu_container {
        width: 60%;

        @media only screen and (max-width: 1200px) {
            width: 85%;
        }

        @media only screen and (max-width: 760px) {
            width: 30%;
        }

        @media only screen and (max-width: 760px) {

            width: 25%;
        }

        @media only screen and (max-width: 560px) {

            width: 30%;
        }

        @media only screen and (max-width: 380px) {

            width: 35%;
        }

        .items {
            .navbar {
                width: 100%;
                display: flex;
                justify-content: space-around;
                margin-right: 15px;

                .nav_item,
                .nav_item_resume {
                    @extend %h3_style;
                    font-weight: 400;
                    font-size: 1.2vw;
                    color: $white;
                    margin: 0 15px;
                    cursor: pointer;

                    @media only screen and (max-width: 1200px) {
                        font-size: 1.4vw;
                    }

                    @media only screen and (max-width: 960px) {
                        font-size: 1.9vw;
                    }

                    .switcher {
                        position: relative;
                        text-transform: uppercase;
                        cursor: pointer;

                        .choose_lang_box_active {
                            position: absolute;
                            top: 35px;
                            left: 0;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .lang_box {
                                font-family: $font;
                                font-weight: 400;
                                color: $white;
                                font-size: 20px;
                                margin-bottom: 8px;
                            }
                        }

                        .choose_lang_box {
                            display: none;
                        }
                    }

                    .resumeicon {
                        width: 25px;
                        height: 25px;
                        position: relative;
                        margin-left: 10px;
                        fill: $mint;
                        cursor: pointer;
                    }
                }

                .nav_item {
                    @media only screen and (max-width: 760px) {
                        display: none;
                    }
                }

                .nav_item_resume {
                    @media only screen and (max-width: 760px) {
                        margin-top: 15px;
                    }
                }

                .div_icon_burger {
                    width: 45%;
                    display: none;

                    @media only screen and (max-width: 760px) {
                        display: block;
                    }

                    @media only screen and (max-width: 360px) {
                        margin-right: 8px;
                    }

                    .burger {
                        width: 50px;
                        height: 50px;
                        fill: $mint;
                        margin-top: 5px;
                        text-align: center;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}


.resume_popup_back {
    background-color: $background;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    overflow-x: auto;
    overflow-y: hidden;
    z-index: 3;
    transition: all 0.8s ease;
    -webkit-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -ms-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;

    .resume_popup_body {
        min-height: 100%;
        position: relative;
        display: flex;
        padding: 15px 15px;
        justify-content: flex-end;
        transition: all 0.8s ease 0s;
        -webkit-transition: all 0.8s ease 0s;
        -moz-transition: all 0.8s ease 0s;
        -ms-transition: all 0.8s ease 0s;
        -o-transition: all 0.8s ease 0s;

        .resume_popup_content {
            width: 30vw;
            height: 200px;
            position: absolute;
            top: 20px;
            right: 15px;
            background-color: $white;
            border-radius: 5px;
            padding: 0;
            opacity: 0;
            transition: all 2s ease 0s;
            -webkit-transition: all 2s ease 0s;
            -moz-transition: all 2s ease 0s;
            -ms-transition: all 2s ease 0s;
            -o-transition: all 2s ease 0s;
            transform-origin: top right;
            -webkit-transform-origin: top right;
            -ms-transform-origin: top right;
            transform: rotate(180deg);
            animation: rotate 2s linear;
            -webkit-animation: rotate 2s linear;
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);

            @keyframes rotate {
                0% {
                    transform: rotate(360deg);
                    -webkit-transform: rotate(360deg);
                    -moz-transform: rotate(360deg);
                    -ms-transform: rotate(360deg);
                    -o-transform: rotate(360deg);
                }

                100% {
                    transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                }
            }

            @media only screen and (max-width: 1200px) {
                width: 500px;
            }

            @media only screen and (max-width: 960px) {
                width: 60vw;
            }

            @media only screen and (max-width: 780px) {
                width: 80vw;
            }

            @media only screen and (max-width: 560px) {
                width: 90vw;
            }

            .title_popup {
                width: 100%;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                // opacity: 0;
                box-shadow: 0 5px 5px -5px rgba(0, 0, 0, .5);
                padding: 10px 0 10px;
                margin-bottom: 20px;
                box-sizing: border-box;
                text-align: center;
                background-color: $title_background;
                background: $gradient;
                border-bottom: 1px solid darkgray;
                position: relative;
                // @extend %h3_style;
                // color: $mint;

                .close_popup {
                    border-radius: 50%;
                    padding: 3px;
                    background-color: $white;
                    position: absolute;
                    margin-right: 5px;
                    margin-top: 3px;
                    top: 10px;
                    right: 8px;
                    width: 10px;
                    height: 10px;
                    cursor: pointer;
                    fill: $graphite;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                }

                .h3 {
                    margin-top: 0;
                    margin-bottom: 0;
                    // font-family: "Catamaran", sans-serif;
                    // font-weight: 600;
                    // font-size: 18px;
                    @extend %h3_style;
                    color: $mint;

                }
            }

            .container_mes {
                padding: 1%;
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;
                // opacity: 0;

                .row_messenger {
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;

                    a {
                        display: block;
                        text-decoration: none;

                        .icon {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            .doc_icon {
                                width: 50px;
                                height: 50px;
                                margin: 0 30px;

                                @media only screen and (max-width: 760px) {
                                    width: 40px;
                                    height: 40px;
                                }

                                @media only screen and (max-width: 560px) {
                                    width: 35px;
                                    height: 35px;
                                }
                            }

                            .sign_doc {
                                font-family: "Catamaran", sans-serif;
                                font-weight: 500;
                                font-size: 16px;
                                color: #0a9995;

                                @media only screen and (max-width: 760px) {
                                    font-size: 15px;
                                }

                                @media only screen and (max-width: 560px) {
                                    font-size: 14px;
                                }
                            }
                        }
                    }

                    @media only screen and (max-width: 780px) {
                        justify-content: center;
                    }
                }
            }

        }
    }
}


.resume_popup_back_open {
    @extend .resume_popup_back;
    opacity: 1;
    visibility: visible;

    .resume_popup_body {

        .resume_popup_content {
            opacity: 1;
            transition: all 2s ease 0s;
            -webkit-transition: all 2s ease 0s;
            -moz-transition: all 2s ease 0s;
            -ms-transition: all 2s ease 0s;
            -o-transition: all 2s ease 0s;
            transform: rotate(0);
            -webkit-transform: rotate(0);
            -moz-transform: rotate(0);
            -ms-transform: rotate(0);
            -o-transform: rotate(0);

            @keyframes rotate {
                0% {
                    transform: rotate(180deg);
                    -webkit-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                    opacity: 0;
                    width: 0;
                    height: 0;
                }

                100% {
                    transform: rotate(360deg);
                    -webkit-transform: rotate(360deg);
                    -moz-transform: rotate(360deg);
                    -ms-transform: rotate(360deg);
                    -o-transform: rotate(360deg);
                    opacity: 1;
                    width: 30vw;
                    height: 200px;
                }
            }
        }
    }
}


.mobilelist_back {
    background-color: $background;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    visibility: hidden;
    overflow-x: auto;
    overflow-y: hidden;
    z-index: 3;

    .mobilelist_body {
        min-height: 100%;
        display: flex;
        padding: 15px 15px;
        justify-content: flex-end;
        transition: all 0.8s ease 0s;
        -webkit-transition: all 0.8s ease 0s;
        -moz-transition: all 0.8s ease 0s;
        -ms-transition: all 0.8s ease 0s;
        -o-transition: all 0.8s ease 0s;

        .mobilelist_content {
            width: 90vw;
            height: 430px;
            position: absolute;
            top: 0;
            right: 0;
            background-color: $white;
            border-bottom-left-radius: 5px;
            padding: 0;
            opacity: 1;
            transition: all 0.8s ease-in 0s;
            -webkit-transition: all 0.8s ease-in 0s;
            -moz-transition: all 0.8s ease-in 0s;
            -ms-transition: all 0.8s ease-in 0s;
            -o-transition: all 0.8s ease-in 0s;
            transform: translate(110%, 0px);
            -webkit-transform: translate(110%, 0px);
            -moz-transform: translate(110%, 0px);
            -ms-transform: translate(110%, 0px);
            -o-transform: translate(110%, 0px);

            .title {
                height: 50px;
                text-align: center;
                background-color: $background;
                background: $gradient;

                .h3 {
                    @extend %h3_style;
                    color: $mint;
                    margin: 0;
                    line-height: 50px;
                }
            }

            .items_m {
                ul {
                    margin-left: 25px;
                    margin-bottom: 20px;

                    li {
                        margin: 5px 0;
                        list-style-type: none;
                        @extend %big_description;
                        font-weight: 500;
                        color: $mint;
                        line-height: 45px;
                    }
                }
            }

            .language {
                margin-left: 25px;

                select {
                    @extend %big_description;
                    color: $mint;
                    display: block;
                    border: 1px dotted $graphite;
                    background-color: $white;
                    @extend %size_select;
                    height: 25px;
                    border-radius: 5px;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    -ms-border-radius: 5px;
                    -o-border-radius: 5px;

                    &:focus {
                        border: 1px dotted $title_background;
                        background-color: $white;
                        outline: none;
                    }

                    &:hover {
                        border: 1px dotted $title_background;
                        background-color: $white;
                        outline: none;
                    }

                    option {
                        color: $graphite;
                        width: 35vw;
                        @extend %size_select;
                        @extend %big_description;
                        color: $mint;
                        padding: 5px 10px;
                    }
                }
            }
        }
    }
}

.mobilelist_back_open {
    @extend .mobilelist_back;
    visibility: visible;

    .mobilelist_body {
        position: relative;

        .mobilelist_content {
            transition: all 1.5s ease 0s;
            -webkit-transition: all 1.5s ease 0s;
            -moz-transition: all 1.5s ease 0s;
            -ms-transition: all 1.5s ease 0s;
            -o-transition: all 1.5s ease 0s;
            transform: translate(0px, 0%);
            -webkit-transform: translate(0px, 0%);
            -moz-transform: translate(0px, 0%);
            -ms-transform: translate(0px, 0%);
            -o-transform: translate(0px, 0%);
        }
    }
}